import { useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'

import {
  GetEntity,
  GetEntityQueryResponse,
  GetEntityQueryVariables,
} from '../../graphql/getEntity.generated'
import { rvEntityDetails } from '../../graphql/reactiveVariables'

export const useEntityQuery = () => {
  const [getEntityData, { loading, error, called, data }] = useLazyQuery<
    GetEntityQueryResponse,
    GetEntityQueryVariables
  >(GetEntity, {
    errorPolicy: 'all',
  })

  useEffect(() => {
    if (data?.getEntity) {
      rvEntityDetails(data.getEntity)
    }
  }, [data])

  return {
    error,
    loading,
    getEntityData,
    entity: data?.getEntity,
    called,
  }
}
