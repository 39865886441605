export const TERMS_CONDITIONS_URL = 'https://www.myzeller.com/terms'
export const PROMOTION_TERMS_CONDITIONS_URL =
  'https://www.myzeller.com/promotion-terms-conditions'
export const LEGAL_URL = 'https://www.myzeller.com/legal'
export const PRIVACY_POLICY_URL = 'https://www.myzeller.com/privacy'
export const EXTERNAL_SHOP_URL = 'https://shop.myzeller.com'
export const ZELLER_SUPPORT_CENTRE_URL = 'https://support.myzeller.com'
export const ZELLER_SUPPORT_USER_ACCESS_LEVELS_URL =
  'https://support.myzeller.com/what-can-each-user-access-level-do'
export const ZELLER_SUPPORT_URL = 'https://myzeller.com/support'
export const ZELLER_SUPPORT_CENTER_URL = 'https://support.myzeller.com'
export const ZELLER_SIM_SFOA = 'https://myzeller.com/sim-sfoa'
export const ZELLER_SIM = 'https://www.myzeller.com/sims'
export const MY_ZELLER_URL = 'https://myzeller.com'
export const DOCUMENTS_CERTIFICATION_URL =
  'https://support.myzeller.com/how-do-i-get-my-documents-certified'
export const EXTERNAL_SHOP_SIM_URL =
  'https://shop.myzeller.com/sim-card-for-zeller-terminal'
export const SUPPORT_EMAIL = 'accountservices@myzeller.com'
export const SUPPORT_PHONE = '1800 935 537'
export const EXTERNAL_TIO = 'https://www.tio.com.au/complaints'
export const CONTACTS_INTRODUCTION =
  'https://www.myzeller.com/blog/zeller-contact-directory-your-path-to-better-business-relationships'

export const REPLACE_OUTDATED_BANK_URL =
  'https://www.myzeller.com/blog/replace-your-outdated-business-bank-with-zeller'
export const PRODUCT_DISCLOSURE_STATEMENT_URL =
  'https://www.myzeller.com/product-disclosure-statement-enhanced-zeller-payment-services'
export const TARGET_MARKET_DETERMINATION_URL =
  'https://www.myzeller.com/enhanced-zeller-payment-services-target-market-determination'
export const FINANCIAL_SERVICE_GUIDE_URL =
  'https://www.myzeller.com/financial-services-guide'

export const BUSINESS_TRANSACTION_ACCOUNT_INFO_URL =
  'https://www.myzeller.com/business-transaction-account'

export const MANAGE_XERO_URL = 'https://www.xero.com/au/'

export const ZELLER_APP_LINK_IOS =
  'https://apps.apple.com/au/app/zeller/id6444711724'
export const ZELLER_APP_LINK_ANDROID =
  'https://play.google.com/store/apps/details?id=com.zeller.zellerapp&pli=1'
export const APPLE_PAY_BLOG_LINK =
  'https://www.myzeller.com/blog/pay-on-your-zeller-debit-card-with-apple-pay-zeller'

export const JOIN_WAIT_LIST_EXTERNAL_LINK =
  'https://share.hsforms.com/1JtdmDLUCSzax-Oxj206Rlw49tgw'

export const TAP_TO_PAY_LEARN_MORE_URL =
  'https://www.myzeller.com/zeller-app/tap-to-pay'
