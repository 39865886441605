import { FC } from 'react'
import { useReactiveVar } from '@apollo/client'
import { OnboardingStatus } from '@npco/mp-gql-types'
import { rvEntityDetails } from '@npco/mp-utils-selected-entity'
import { useOnboardingEntityStatus } from 'features/OnboardingApp/shared/useOnboardingEntityStatus'

export function withIsOnboardedGuard<T extends JSX.IntrinsicAttributes>(
  Component: FC<T>
) {
  return (props: T) => {
    const entityDetails = useReactiveVar(rvEntityDetails)
    const { navigateUserAccordingToOnboardingStatus } =
      useOnboardingEntityStatus()

    if (
      entityDetails.onboardingStatus === OnboardingStatus.ONBOARDED ||
      entityDetails.onboardingStatus === OnboardingStatus.RC_ONBOARDED ||
      !entityDetails.id
    ) {
      return <Component {...props} />
    }

    navigateUserAccordingToOnboardingStatus(entityDetails)
    return null
  }
}
