import { createTranslations } from '@npco/utils-translations'

export const translations = createTranslations({
  createItem: 'Create Item',
  label: 'Name',
  noRecentlyUsedItemsDescription:
    'Recently used items become available as you create invoices',
  noRecentlyUsedItemsTitle: 'No Recent Items yet',
  noSavedItemsTitle: 'No Saved items yet',
  noResultsDescription: 'There are no items that meet the criteria.',
  NoResultsDescriptionNoPermission:
    'There are no items that meet the criteria. Please contact your Admin to be granted Item management permissions.',
  noResultsTitle: 'No Items Found',
})
