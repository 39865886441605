import { useEffect, useMemo, useRef, useState } from 'react'
import { useMutation, useReactiveVar } from '@apollo/client'
import { rvEntityDetails } from '@npco/mp-utils-selected-entity'
import { SelectDepositAccount } from 'apps/component-merchant-portal/src/graphql/merchant-portal/mutations/deposits'
import { rvThirdPartyAccounts } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'
import { useAppStateMapper } from 'auth/useAppStateMapper'

import { useAccounts } from 'hooks/banking'
import { useGetThirdPartyAccounts } from 'hooks/useGetThirdPartyAccounts'
import { DebitCardAccount, ThirdPartyBankAccount } from 'types/accounts'
import { DepositsApiCallTypeEnum } from 'types/auth'
import { ErrorType, ServerError } from 'types/errors'
import {
  SelectDepositAccount as SelectDepositAccountResponse,
  SelectDepositAccountVariables,
} from 'types/gql-types/SelectDepositAccount'
import { Account } from 'types/settings'
import { useDepositsSettingsContext } from 'pages/Deposits/DepositsSettingsContext/DepositsSettingsContext'

export const useDepositAccount = () => {
  const { isLoadingAccounts, accountById } = useAccounts()
  const { isLoading: isLoadingThirdPartyAccount } = useGetThirdPartyAccounts()
  const thirdPartyAccounts = rvThirdPartyAccounts()

  const [isLoading, setIsLoading] = useState(true)

  const { remitToCard, debitCardAccountUuid, depositAccountUuid } =
    useReactiveVar(rvEntityDetails)

  const [depositAccount, setDepositAccount] = useState<
    ThirdPartyBankAccount | DebitCardAccount | null
  >(null)

  const account = useMemo(() => {
    return (
      (remitToCard
        ? accountById(debitCardAccountUuid)
        : thirdPartyAccounts?.find((a) => a.id === depositAccountUuid)) || null
    )
  }, [
    remitToCard,
    depositAccountUuid,
    debitCardAccountUuid,
    thirdPartyAccounts,
    accountById,
  ])

  useEffect(() => {
    setDepositAccount(account)
  }, [account])

  useEffect(() => {
    if (!isLoadingAccounts && !isLoadingThirdPartyAccount) {
      setIsLoading(false)
    }
  }, [isLoadingAccounts, isLoadingThirdPartyAccount])

  return {
    depositAccount,
    remitToCard,
    isLoading,
  }
}

export const useDepositAccountSelection = (accountType: Account) => {
  const { mapDepositStateToAuthObject } = useAppStateMapper()
  const { setDepositsAuth0AppState, setRequiredStepUpAuth } =
    useDepositsSettingsContext()
  const debitAccountRef = useRef<{
    id: string
    name: string
    isNewAccount?: boolean
  }>({
    id: '',
    name: '',
  })

  const isMfaRequiredOrExpired = (response: any): boolean => {
    const isMfaRequired = response?.errors
      ? (response.errors.graphQLErrors[0] as ServerError).errorType ===
        ErrorType.MFA_REQUIRED
      : false

    const isMfaExpired = response?.errors
      ? (response.errors.graphQLErrors[0] as ServerError).errorType ===
        ErrorType.MFA_SENSITIVE_ACCESS_EXPIRED
      : false

    return isMfaRequired || isMfaExpired
  }

  const handleSelectDepositAccountError = () => {
    const appState = mapDepositStateToAuthObject(
      DepositsApiCallTypeEnum.SELECT,
      {
        id: debitAccountRef.current.id,
        remitToCard: accountType === Account.ZELLER,
      },
      debitAccountRef.current.name,
      Boolean(debitAccountRef.current.isNewAccount)
    )

    setDepositsAuth0AppState(appState)
    setRequiredStepUpAuth()
  }

  const [selectDepositAccount, { loading: isLoading }] = useMutation<
    SelectDepositAccountResponse,
    SelectDepositAccountVariables
  >(SelectDepositAccount, {
    onError: ({ graphQLErrors: [graphQLError] }) => {
      const error = graphQLError as ServerError

      if (
        error.errorType === ErrorType.MFA_REQUIRED ||
        error.errorType === ErrorType.MFA_SENSITIVE_ACCESS_EXPIRED
      ) {
        handleSelectDepositAccountError()
      } else {
        throw new Error('an error occurred')
      }
    },
  })

  return {
    selectDepositAccount: (
      debitCardAccountUuid: string,
      name: string,
      isNewAccount?: boolean
    ) => {
      debitAccountRef.current = { id: debitCardAccountUuid, name, isNewAccount }
      return selectDepositAccount({
        variables: {
          id: debitCardAccountUuid,
          remitToCard: accountType === Account.ZELLER,
        },
      })
    },
    isLoading,
    isMfaRequiredOrExpired,
  }
}
