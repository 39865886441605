import { useCallback, useEffect, useRef, useState } from 'react'

type useCountdownTimerProps = {
  countdownTime: number
}

export const useCountdownTimer = ({
  countdownTime,
}: useCountdownTimerProps) => {
  const [countdown, setCountdown] = useState(countdownTime)
  const [isTimeout, setIsTimeout] = useState(false)
  const workerRef = useRef<Worker | null>(null)

  const handleWorkerMessage = useCallback((e: MessageEvent) => {
    const { type, countdownTime: newCountdownTime } = e.data

    if (type === 'tick') {
      if (newCountdownTime !== undefined) {
        setCountdown(newCountdownTime)
      }
    } else if (type === 'timeout') {
      setIsTimeout(true)
    }
  }, [])

  useEffect(() => {
    workerRef.current = new Worker(
      new URL('../../../WebWorker/countdownTimerWorker', import.meta.url),
      { type: 'module' }
    )

    workerRef.current.onmessage = (e) => handleWorkerMessage(e)
    return () => {
      if (workerRef.current) {
        workerRef.current.terminate()
      }
    }
  }, [handleWorkerMessage])

  const clearCountdownTimer = useCallback(() => {
    workerRef.current?.postMessage({ type: 'clearCountdown' })
  }, [])

  const startCountdown = useCallback(() => {
    setCountdown(countdownTime)
    setIsTimeout(false)
    workerRef.current?.postMessage({
      type: 'startCountdown',
      payload: { countdownTime },
    })
  }, [countdownTime])

  return {
    countdown,
    isTimeout,
    startCountdown,
    clearCountdownTimer,
    handleWorkerMessage,
  }
}
