import { useLoggedInUser } from '@npco/mp-utils-logged-in-user'
import { useFeatureFlags } from '@npco/mp-utils-mp-feature-flags'

import { translate } from 'utils/translations'

import { StyledHeading } from './DashboardHeader.styled'
import { getTranslationString } from './DashboardHeader.utils'

export const DashboardHeader = () => {
  const { userData } = useLoggedInUser()
  const flags = useFeatureFlags()
  return (
    <StyledHeading $marginTop={flags.MultiEntity ? '4px' : '8px'}>
      {translate(getTranslationString(), {
        firstName: userData?.firstname || '',
      })}
    </StyledHeading>
  )
}
