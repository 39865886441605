import { ReactNode } from 'react'
import {
  ApolloClient,
  ApolloProvider,
  createQueryPreloader,
} from '@apollo/client'
import { cache } from 'apps/component-merchant-portal/src/graphql/cache'

import { API_URI, IS_DEVELOPMENT, IS_TEST } from 'const/envs'

import { ApolloMiddleware } from './ApolloMiddleware'

const client = new ApolloClient({
  cache,
  connectToDevTools: IS_DEVELOPMENT,
})

export const preloadQuery = createQueryPreloader(client)

interface AuthorizedApolloProviderProps {
  children: ReactNode | ReactNode[] | null
}

export const AuthorizedApolloProvider = ({
  children,
}: AuthorizedApolloProviderProps) => (
  <ApolloProvider client={client}>
    <ApolloMiddleware apiUri={API_URI} isTest={IS_TEST}>
      {children}
    </ApolloMiddleware>
  </ApolloProvider>
)
