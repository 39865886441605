import { DATE_FULL_DISPLAY_FORMAT } from '@npco/component-mp-common'
import {
  CreateInvoiceItemInput,
  InvoiceDiscountConfig,
  InvoiceDiscountInput,
  InvoiceTaxInput,
  RevisionStatus,
  UpdateInvoiceInput,
} from '@npco/mp-gql-types'
import currency from 'currency.js'
import {
  InvoiceFormFields,
  InvoiceItem,
} from 'features/Invoicing/components/Invoices/Invoice/Invoice.types'
import { ITEM_TAX_RATE } from 'features/Items/components/Catalogue/CatalogueTable/CatalogueItem/CatalogueItem.constants'
import isEqual from 'lodash/isEqual'

import dayjs from 'utils/dayjs'
import { convertLocaleStringToNumber } from 'utils/localeString'
import { CreateInvoiceVariables } from 'types/gql-types/CreateInvoice'

import { initialValues } from '../InvoiceCreateForm.utils'

export const convertFormFieldsDiscountToInvoiceDiscountInput = (
  formValue: InvoiceFormFields['discount'] | InvoiceItem['discount']
): InvoiceDiscountInput => {
  const isPercentageType = formValue.config === InvoiceDiscountConfig.PERCENTAGE

  const value = isPercentageType
    ? convertLocaleStringToNumber(formValue.percentage, NaN)
    : currency(formValue.price, { errorOnInvalid: true, precision: 4 }).intValue

  if (Number.isNaN(value)) {
    throw new Error('Invoicing: invalid discount')
  }

  return {
    config: formValue.config,
    value: value.toString(),
  }
}

export const convertFormFieldItemToInvoiceTaxInput = (
  item: InvoiceItem
): InvoiceTaxInput => ({
  enabled: item.taxApplicable,
  name: 'GST',
  percent: ITEM_TAX_RATE,
})

export const convertFormFieldsItemsToInvoiceItemInput = (
  formValue: InvoiceFormFields['items']
): CreateInvoiceItemInput[] =>
  formValue.map((item, index) => {
    const quantity = convertLocaleStringToNumber(item.quantity, NaN)
    const unitPrice = currency(item.price, { precision: 4 }).intValue

    if (Number.isNaN(unitPrice) || Number.isNaN(quantity)) {
      throw new Error('Invoicing: invalid quantity or unit price')
    }

    const discount = convertFormFieldsDiscountToInvoiceDiscountInput(
      item.discount
    )

    const taxes = convertFormFieldItemToInvoiceTaxInput(item)

    return {
      catalogItemUuid: item.catalogItemUuid || null,
      description: item.description,
      discount,
      id: item.id,
      name: item.name,
      orderIndex: index + 1,
      price: unitPrice.toString(),
      quantity,
      taxes: [taxes],
      unit: item.unit,
      updatedTime: null,
    }
  })

export const convertFormFieldsEmailToInvoiceEmailInput = (
  email: InvoiceFormFields['delivery']['email']
) => {
  if (!email.isEnabled || isEqual(email, initialValues.delivery.email)) {
    return null
  }
  return {
    enabled: email.isEnabled,
    body: email.message || null,
    recipients: {
      bcc: email.bcc,
      cc: email.cc,
      recipient: email.recipient.trim(),
      sendMeCopy: email.sendMeCopy,
    },
    subject: email.subject || null,
  }
}

export const getInputDate = (date: string, dayJsOp: 'startOf' | 'endOf') =>
  dayjs(date, DATE_FULL_DISPLAY_FORMAT)[dayJsOp]('day').toDate()

export const convertFormFieldsToInvoiceInput = ({
  customer,
  discount,
  delivery: { email, sms },
  items,
  schedule,
  itemsApplyTax,
  itemsTaxInclusive,
  title,
}: InvoiceFormFields): CreateInvoiceVariables['invoice'] => {
  const invoiceDiscount =
    convertFormFieldsDiscountToInvoiceDiscountInput(discount)
  const invoiceItemsInput = convertFormFieldsItemsToInvoiceItemInput(items)

  const customerInput = !isEqual(customer, initialValues.customer)
    ? {
        attentionContactUuid: customer.attentionToContact?.contactUuid || null,
        payerContactUuid: customer.payerContact?.contactUuid ?? '',
        payerEmail: email.isEnabled ? email.recipient.trim() : '',
      }
    : null

  const sendSchedule = schedule.sendEnabled
    ? {
        enabled: schedule.sendEnabled,
        sendDate: getInputDate(schedule.sendDate, 'startOf'),
      }
    : null

  const scheduleInput = {
    dueDate: schedule.dueDate ? getInputDate(schedule.dueDate, 'endOf') : null,
    startDate: schedule.invoiceDate
      ? getInputDate(schedule.invoiceDate, 'startOf')
      : null,
    sendSchedule,
  }

  const titleInput = {
    title: title.header.trim() || null,
    message: title.message.trim() || null,
  }

  const emailInput = convertFormFieldsEmailToInvoiceEmailInput(email)

  const smsInput =
    sms.isEnabled && !isEqual(sms, initialValues.delivery.sms)
      ? {
          enabled: sms.isEnabled,
          payerContactPhoneNumber: sms.recipient,
        }
      : null

  return {
    customer: customerInput,
    discount: invoiceDiscount,
    email: emailInput,
    items: invoiceItemsInput,
    itemsApplyTax,
    itemsTaxInclusive,
    sms: smsInput,
    ...scheduleInput,
    ...titleInput,
  }
}

export const convertFormFieldsToUpdateInvoiceInput = (
  values: InvoiceFormFields
): UpdateInvoiceInput => {
  const createInput = convertFormFieldsToInvoiceInput(values)
  return {
    ...createInput,
    customer: createInput.customer
      ? {
          ...createInput.customer,
          ...(createInput.customer.attentionContactUuid && {
            attentionContactStatus: RevisionStatus.NO_CHANGE,
          }),
          ...(createInput.customer.payerContactUuid && {
            payerContactStatus: RevisionStatus.NO_CHANGE,
          }),
        }
      : null,
    referenceNumber: values.referenceNumber,
  }
}
