import { Route, Routes } from 'react-router-dom-v5-compat'
import { ManageBusiness } from '@npco/mp-feature-manage-businesses'
import { useFeatureFlags } from '@npco/mp-utils-mp-feature-flags'

import { ROOT } from 'const/routes'

export const MultiEntityRoutes = () => {
  const flags = useFeatureFlags()

  return (
    <Routes>
      <Route
        path={ROOT.ORGS.MANAGE_BUSINESS.relative}
        element={
          flags.MultiEntity ? <ManageBusiness /> : <div>no page found</div>
        }
      />
    </Routes>
  )
}
