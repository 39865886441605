import {
  Category,
  CategoryGroup,
  EntityType,
  OnboardingStatus,
} from '@npco/mp-gql-types'
import { mock } from '@npco/utils-apollo-testing-link'

import { GetEntity } from '../getEntity.generated'
import { LoginEntityType } from '../getEntity.types'

export const MOCKED_ENTITY_DATA: LoginEntityType = {
  __typename: 'Entity',
  id: '196e1f55-60f0-4807-b989-ac18cb28d2ce',
  name: 'Ekstrom Bothman Bros',
  type: EntityType.COMPANY,
  acn: '',
  abn: '',
  registeredAddress: {
    street1: 'Level 2, 1 Pitt Street',
    street2: '',
    suburb: 'Sydney',
    state: 'NSW',
    postcode: '2000',
    __typename: 'EntityAddress',
  },
  businessAddress: {
    street1: 'Level 2, 1 Pitt Street',
    street2: '',
    suburb: 'Sydney',
    state: 'NSW',
    postcode: '2000',
    __typename: 'EntityAddress',
  },
  feeRateSettings: {
    feePercent: 130,
    feePercentMoto: 140,
    feePercentCpoc: 100,
    feeFixedCpoc: 100,
    feeFixedVt: 10,
    feePercentVt: 150,
    __typename: 'FeeRateSettings',
  },
  categoryGroup: CategoryGroup.EDUCATION,
  category: Category.ACCOUNTING,
  estimatedAnnualRevenue: 10000000000,
  onboardingStatus: OnboardingStatus.ONBOARDED,
  canAcquire: false,
  canAcquireMobile: false,
  canAcquireVt: false,
  canAcquireMoto: true,
  remitToCard: null,
  debitCardAccountUuid: 'test',
  depositAccountUuid: 'testid',
  regulatorBody: null,
  termsOfService: null,
  transactionMetaData: null,
}

export const GetEntityMock = mock(GetEntity)
