import { useCallback, useEffect, useMemo, useState } from 'react'
import { useApolloClient } from '@apollo/client'
import {
  GetEntity,
  GetEntityQueryResponse,
} from '@npco/mp-utils-selected-entity'
import { isEqual } from 'lodash-es'

import { useGetZellerInvoiceSettingsUpdateSubscription } from '../../graphql/onZellerInvoiceSettingsUpdate.generated'
import {
  useLogoUpdateTimeout,
  UseLogoUpdateTimeoutProps,
} from './useLogoUpdateTimeout'
import { useUpdateSettingsCustomisation } from './useUpdateSettingsCustomisation'

export const useSettingsCustomisationLogoSubscription = ({
  logos,
  onError,
  onLogosUpdated,
}: {
  logos: UseLogoUpdateTimeoutProps['logos']
  onError: UseLogoUpdateTimeoutProps['onError']
  onLogosUpdated: UseLogoUpdateTimeoutProps['onLogosUpdated']
}) => {
  const [shouldCancelSubscription, setShouldCancelSubscription] =
    useState(false)
  const {
    invoiceSettings,
    isLoadingSettings,
    isUpdatingZellerInvoiceSettings,
    refetch,
    updateInvoiceSettingsCache,
    updateSettingsCustomisation,
  } = useUpdateSettingsCustomisation({
    onError: () => {
      setShouldCancelSubscription(true)
      onError()
    },
  })

  // NOTE: manage our own subscription loading state because the hook doesn't reset it
  // https://github.com/apollographql/apollo-client/issues/7198#issuecomment-714532335
  const [isLoadingSubscription, setIsSubscriptionLoading] = useState(false)

  const [settingsSubscriptionLogos, setSettingsSubscriptionLogos] = useState(
    invoiceSettings?.invoice.customisation?.logos ?? []
  )
  const timeoutCallback = useCallback(() => {
    setIsSubscriptionLoading(false)
    refetch()
  }, [refetch])

  const { lastUploadFailed, timer: subscriptionTimer } = useLogoUpdateTimeout({
    callback: timeoutCallback,
    invoiceSettingsLogos: invoiceSettings?.invoice.customisation?.logos ?? [],
    logos,
    isLoading: isLoadingSettings || isLoadingSubscription,
    onError,
    onLogosUpdated,
    setSettingsSubscriptionLogos,
  })

  const client = useApolloClient()
  const entityResponse = client.cache.readQuery<GetEntityQueryResponse>({
    query: GetEntity,
  })
  const entityUuid = entityResponse?.getEntity?.id

  useGetZellerInvoiceSettingsUpdateSubscription({
    onError: () => {
      subscriptionTimer.cancel()
      if (!isLoadingSubscription) return
      onError()
      setIsSubscriptionLoading(false)
    },
    onData: ({ data }) => {
      subscriptionTimer.cancel()
      if (
        !invoiceSettings?.invoice.customisation ||
        invoiceSettings.invoice.customisation.logos === logos
      )
        return

      const updatedLogos =
        data.data?.onZellerInvoiceSettingsUpdate?.customisation?.logos ?? []

      onLogosUpdated({ logos: updatedLogos })
      setSettingsSubscriptionLogos(updatedLogos)
      setIsSubscriptionLoading(false)
      updateInvoiceSettingsCache({
        ...invoiceSettings,
        invoice: {
          ...invoiceSettings.invoice,
          customisation: {
            ...invoiceSettings.invoice.customisation,
            logos: updatedLogos,
          },
        },
      })
    },
    shouldResubscribe: true,
    skip: !isLoadingSubscription,
    variables: {
      siteUuid: invoiceSettings?.id ?? '',
      entityUuid: entityUuid ?? '',
    },
  })

  const logosAreSynced = useMemo(
    () => !lastUploadFailed && isEqual(settingsSubscriptionLogos, logos),
    [lastUploadFailed, settingsSubscriptionLogos, logos]
  )

  const startSubscription = useCallback(() => {
    subscriptionTimer.restart()
    setIsSubscriptionLoading(true)
  }, [subscriptionTimer])

  useEffect(() => {
    if (shouldCancelSubscription) {
      subscriptionTimer.cancel()
      setShouldCancelSubscription(false)
      setIsSubscriptionLoading(false)
    }
  }, [shouldCancelSubscription, subscriptionTimer])

  return {
    invoiceSettings,
    isLoadingSettings,
    isLoadingSubscription,
    isUpdatingZellerInvoiceSettings,
    logosAreSynced,
    startSubscription,
    updateSettingsCustomisation,
  }
}

export type UseSettingsCustomisationLogoSubscriptionReturnType = ReturnType<
  typeof useSettingsCustomisationLogoSubscription
>
