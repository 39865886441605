import { OnSubscriptionDataOptions, useApolloClient } from '@apollo/client'
import { ItemCategoryStatus } from '@npco/mp-gql-types'
import {
  GetEntity,
  GetEntityQueryResponse,
} from '@npco/mp-utils-selected-entity'

import { useSubscription } from 'hooks/useSubscription'

import { GetItemManagementLocalState } from '../../../graphql/getItemManagementLocalState'
import { CacheLocalStateItemManagement } from '../../../ItemManagement.types'
import { DEFAULT_GET_LIMIT } from '../../../ItemManagement.utils'
import {
  GetCategoriesTableCoreFieldsDoc,
  GetCategoriesTableCoreFieldsFragment,
} from './graphql/getCategoriesTableCoreFields.generated'
import {
  GetCategoriesTable,
  GetCategoriesTableQueryResponse,
} from './graphql/getCategoriesTableQuery.generated'
import {
  GetCategoriesTableSubscription,
  GetCategoriesTableSubscriptionResponse,
  GetCategoriesTableSubscriptionVariables,
} from './graphql/getCategoriesTableSubscription.generated'

export const onGetTableCategoriesSubscriptionData = (
  payload: OnSubscriptionDataOptions<GetCategoriesTableSubscriptionResponse>
) => {
  const { client: c, subscriptionData } = payload
  const data = subscriptionData?.data?.onItemCategoryUpdate

  if (!data?.categories) {
    return
  }

  const localCache = c.readQuery<CacheLocalStateItemManagement>({
    query: GetItemManagementLocalState,
  })

  const filter = localCache?.local.itemManagement.categories.filterInput ?? null

  const cachedCategoriesResponse = c.readQuery<GetCategoriesTableQueryResponse>(
    {
      query: GetCategoriesTable,
      variables: {
        filter,
        limit: DEFAULT_GET_LIMIT,
      },
    }
  )

  const cachedCategories =
    cachedCategoriesResponse?.getItemCategories?.categories ?? []
  const nextToken =
    cachedCategoriesResponse?.getItemCategories?.nextToken ?? null

  const categoriesToAddInCache: GetCategoriesTableCoreFieldsFragment[] = []

  data.categories
    // Do not include deleted categories events
    .filter((category) => category.status !== ItemCategoryStatus.DELETED)
    .forEach((categoryUpdate) => {
      const cachedCategory =
        c.readFragment<GetCategoriesTableCoreFieldsFragment>({
          id: `ItemCategory:${categoryUpdate.id}`,
          fragment: GetCategoriesTableCoreFieldsDoc,
          fragmentName: 'GetCategoriesTableCoreFields',
        })

      // NOTE: a newly created or updated category will appear in the cache
      // immediately as response is stored before on subscription data
      if (cachedCategory) {
        const categoryExistsInQuery = cachedCategories.some(
          (category) => category?.id === cachedCategory?.id
        )

        // NOTE: only write to the list query if the category doesn't exist
        if (!categoryExistsInQuery) {
          categoriesToAddInCache.push(cachedCategory)
        }
      }
    })

  if (categoriesToAddInCache.length) {
    c.writeQuery<GetCategoriesTableQueryResponse>({
      query: GetCategoriesTable,
      variables: {
        filter,
        limit: DEFAULT_GET_LIMIT,
      },
      data: {
        getItemCategories: {
          // NOTE: Always add at the top of the table for now
          // https://npco-dev.atlassian.net/browse/ZD-15369 task to
          // implement filtering and sorting on categories update
          categories: [...categoriesToAddInCache, ...cachedCategories],
          nextToken,
        },
      },
    })
  }
}

export const useGetCategoriesTableSubscription = () => {
  const client = useApolloClient()

  // NOTE: entity data should already be in the cache however if not
  // subscription below will be skipped
  const entityResponse = client.cache.readQuery<GetEntityQueryResponse>({
    query: GetEntity,
  })

  const entityUuid = entityResponse?.getEntity?.id

  const data = useSubscription<
    GetCategoriesTableSubscriptionResponse,
    GetCategoriesTableSubscriptionVariables
  >(GetCategoriesTableSubscription, {
    onSubscriptionData: onGetTableCategoriesSubscriptionData,
    variables: entityUuid ? { entityUuid } : undefined,
    skip: !entityUuid,
  })

  return data
}
