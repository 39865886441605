import { useEffect } from 'react'
import { useReactiveVar } from '@apollo/client'
import { rvEntityDetails } from '@npco/mp-utils-selected-entity'
import { useTranslations } from '@npco/utils-translations'
import {
  Box,
  Flex,
  Heading,
  HeadingStyles,
  RadioGroupFormik,
  RadioListCard,
  ToggleForm,
} from '@npco/zeller-design-system'
import { Field, useFormikContext } from 'formik'

import { CardSurcharge, SettingsPayments } from 'types/settings'
import { SiteCache } from 'types/site'
import { IconsBar } from 'pages/Settings/SitePayments/SitePaymentForm/IconsBar/IconsBar'

import {
  getDisplaySurchargeFeePercentage,
  getMaxCardSurchargeText,
} from '../../SitePayments.utils'
import { Warning } from '../Warning/Warning'
import { translations } from './Cpoc.i18n'
import * as styled from './Cpoc.styled'
import { CpocSurchargeInput } from './CpocSurchargeInput.fields'

interface Props {
  siteData: SiteCache
}

export const Cpoc = ({ siteData }: Props) => {
  const { values, setFieldValue, errors, initialValues } =
    useFormikContext<SettingsPayments>()
  const t = useTranslations(translations)
  const entityDetails = useReactiveVar(rvEntityDetails)

  const lastValidCpocSurcharge = initialValues.cpocSurchargePercent

  useEffect(() => {
    if (!values.cpocSurchargeEnabled && errors.cpocSurchargePercent) {
      setFieldValue('cpocSurchargePercent', lastValidCpocSurcharge)
    }
  }, [
    setFieldValue,
    values.cpocSurchargeEnabled,
    errors.cpocSurchargePercent,
    lastValidCpocSurcharge,
  ])

  if (!entityDetails.canAcquire || !entityDetails.canAcquireMobile) {
    return (
      <>
        <Box mb="24px">
          <Heading.H2>{t('mobileApp')}</Heading.H2>
        </Box>
        <styled.Title>{t('cardPayments')}</styled.Title>
        <Warning />
      </>
    )
  }

  return (
    <Flex flexDirection="column" gridGap="24px">
      <Heading.H2>{t('mobileApp')}</Heading.H2>
      <styled.Title>{t('cardPayments')}</styled.Title>
      <styled.Description>{t('description')}</styled.Description>
      {Boolean(values.schemes?.length) && (
        <IconsBar values={values.schemes} isCpoc />
      )}
      <Flex
        alignItems="center"
        justifyContent="space-between"
        data-testid="cpoc-surcharge-fee"
      >
        <styled.Subtitle withStyles={HeadingStyles.H5}>
          {t('processingFee')}
        </styled.Subtitle>
        <styled.Subtitle withStyles={HeadingStyles.H5}>
          {getDisplaySurchargeFeePercentage(
            siteData.surchargesTaxes?.feesSurchargeCpoc?.feePercent,
            siteData.surchargesTaxes?.feesSurchargeCpoc?.feeFixed
          )}
        </styled.Subtitle>
      </Flex>
      <Flex alignItems="center" justifyContent="space-between">
        <styled.Subtitle withStyles={HeadingStyles.H5}>
          {t('cardPaymentsSurcharge')}
        </styled.Subtitle>
        <div>
          <Field
            component={ToggleForm}
            name="cpocSurchargeEnabled"
            value={values.cpocSurchargeEnabled}
          />
        </div>
      </Flex>
      {values.cpocSurchargeEnabled && (
        <>
          <RadioGroupFormik
            name="cpocSurchargeType"
            isVerticalDisplay
            gap="8px"
          >
            <RadioListCard
              id="cpoc-surcharge-total-fees"
              title={t('totalFees')}
              value={CardSurcharge.TotalFees}
            />
            <RadioListCard
              id="cpoc-surcharge-percentage"
              title={t('percentage')}
              value={CardSurcharge.Percentage}
            />
          </RadioGroupFormik>

          {values.cpocSurchargeType === CardSurcharge.Percentage && (
            <>
              <styled.Description>
                {t('description1')}
                &nbsp;
                {getMaxCardSurchargeText(
                  siteData.surchargesTaxes?.feesSurchargeCpoc?.feePercent
                )}
              </styled.Description>
              <div>
                <CpocSurchargeInput
                  surchargesTaxes={siteData.surchargesTaxes}
                />
              </div>
            </>
          )}
        </>
      )}
    </Flex>
  )
}
