import { Flex, H3 } from '@npco/zeller-design-system'
import styled from 'styled-components'

import { ImagePreview } from 'components/ImagePreview/ImagePreview'

export const StyledImagesTitle = styled(H3)`
  margin-top: 0;
  margin-bottom: 0.75rem;
`

export const StyledImageDisplayWrapper = styled(Flex)`
  color: ${({ theme }) => theme.colors.GREY_550};
  flex-wrap: wrap;
`

export const StyledThumbnailRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
`

export const StyledThumbnail = styled(ImagePreview)<{
  onClick?: (id: string) => void
}>`
  border-radius: 4px;
  cursor: ${({ onClick }) => onClick && 'pointer'};
  overflow: hidden;
`
