import { useCallback, useEffect, useState } from 'react'
import { useTranslations } from '@npco/utils-translations'
import {
  AnnouncementModal,
  HandAndKey,
  NewBadge,
  zIndexMap,
} from '@npco/zeller-design-system'

import { useCountdownTimer } from './hooks'
import { LAST_ACTIVITY_TIME } from './localStorage/keys'
import { sessionTimeoutModalTranslations } from './SessionTimeoutModal.i18n'
import { formatCountdownTime } from './utils/formatCountdownTime'

interface SessionTimeoutModalProps {
  countdownTime: number
  isCountdownActive: boolean
  onTimeout: () => void
  onStayLoggedIn: () => void
  onLogOut: () => void
}

export const SessionTimeoutModal = ({
  countdownTime,
  isCountdownActive,
  onTimeout,
  onStayLoggedIn,
  onLogOut,
}: SessionTimeoutModalProps) => {
  const t = useTranslations(sessionTimeoutModalTranslations)

  const [isModalShown, setIsModalShown] = useState(false)

  const { isTimeout, countdown, startCountdown, clearCountdownTimer } =
    useCountdownTimer({
      countdownTime,
    })

  const handleStayLoggedIn = useCallback(() => {
    onStayLoggedIn()
    clearCountdownTimer()
    setIsModalShown(false)
  }, [clearCountdownTimer, onStayLoggedIn])

  const handleLoggedOut = () => {
    onLogOut()
    clearCountdownTimer()
  }

  useEffect(() => {
    if (isTimeout) {
      onTimeout()
    }
  }, [isTimeout, onTimeout])

  useEffect(() => {
    if (isCountdownActive) {
      startCountdown()
      if (document.visibilityState === 'visible') {
        setIsModalShown(true)
      }
    }
  }, [isCountdownActive, startCountdown])

  const handleVisibilityChange = useCallback(() => {
    if (isCountdownActive && isModalShown) {
      return
    }
    if (document.visibilityState === 'visible') {
      handleStayLoggedIn()
    }
  }, [handleStayLoggedIn, isCountdownActive, isModalShown])

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [countdownTime, handleVisibilityChange])

  useEffect(() => {
    const handleStorageEvent = (event: StorageEvent) => {
      if (event.key === LAST_ACTIVITY_TIME) {
        if (isCountdownActive) {
          handleStayLoggedIn()
        }
      }
    }
    window.addEventListener('storage', handleStorageEvent)

    return () => {
      window.removeEventListener('storage', handleStorageEvent)
    }
  }, [handleStayLoggedIn, isCountdownActive])

  return (
    <AnnouncementModal
      Illustration={<HandAndKey size="large" />}
      badgeContent={<NewBadge text={formatCountdownTime(countdown)} />}
      description={t('description')}
      heading={t('title')}
      onClickPrimary={handleStayLoggedIn}
      onClickSecondary={handleLoggedOut}
      primaryButtonLabel={t('stayLoggedInButton')}
      secondaryButtonLabel={t('logOutButton')}
      isOpen={isCountdownActive && isModalShown}
      onCancel={handleStayLoggedIn}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
      overlayBlur
      zIndex={zIndexMap.announcement}
    />
  )
}
