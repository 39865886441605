import { forwardRef, useMemo } from 'react'
import { zIndexMap } from '@npco/zeller-design-system'

import { Rect, useElementRect } from 'hooks/useElementRect'
import { ForwardedProps } from 'utils/ForwardedProps'

export type Padding = {
  top: number
  bottom: number
  left: number
  right: number
}

const DEFAULT_OVERLAY_STYLE: React.CSSProperties = {
  zIndex: zIndexMap.modalOverlay,
  position: 'absolute',
  background: 'rgba(33,35,34,0.25)',
  transition: 'all 0.3s',
  backdropFilter: 'blur(2px)',
} as const

export const DEFAULT_OVERLAY: React.CSSProperties = {
  ...DEFAULT_OVERLAY_STYLE,
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}

const DEFAULT_SPOTLIGHT_RESIZE_DEBOUNCE_MILLISECONDS: NonNullable<
  ModalOverlayProps['spotlight']
>['resizeDebounceMilliseconds'] = 250

const DEFAULT_SPOTLIGHT_PADDING: Required<
  NonNullable<ModalOverlayProps['spotlight']>['padding']
> = {
  top: 20,
  bottom: 20,
  left: 20,
  right: 20,
}

export const Y_OFFSET = 581 // NOTE: distance from top of screen to lowest spotlight element + 1px border
const MASK_PADDING = {
  x: 10,
  y: 20,
}

export interface ModalOverlayProps {
  spotlight?: {
    selector?: string
    padding?: Partial<Padding>
    resizeDebounceMilliseconds?: number
  }
  children?: React.ReactNode
  className?: string
  forwardedProps?: ForwardedProps<HTMLDivElement>
}

export const SpotlightModalOverlay = forwardRef<
  HTMLDivElement,
  ModalOverlayProps
>(({ spotlight, children, className, forwardedProps }, ref) => {
  const spotlightSelector = spotlight?.selector

  const spotlightPadding = spotlight?.padding

  const spotlightResizeDebounceMilliseconds =
    spotlight?.resizeDebounceMilliseconds ??
    DEFAULT_SPOTLIGHT_RESIZE_DEBOUNCE_MILLISECONDS

  const spotlightRect = useElementRect(spotlightSelector, {
    resizeDebounceMilliseconds: spotlightResizeDebounceMilliseconds,
  })

  const spotlightOverlay = useMemo(() => {
    if (!spotlightRect) {
      return undefined
    }

    const spotlightPaddingTop =
      spotlightPadding?.top ?? DEFAULT_SPOTLIGHT_PADDING.top
    const spotlightPaddingBottom =
      spotlightPadding?.bottom ?? DEFAULT_SPOTLIGHT_PADDING.bottom
    const spotlightPaddingLeft =
      spotlightPadding?.left ?? DEFAULT_SPOTLIGHT_PADDING.left
    const spotlightPaddingRight =
      spotlightPadding?.right ?? DEFAULT_SPOTLIGHT_PADDING.right

    const maskRect: Rect = {
      x: spotlightRect.x - spotlightPaddingLeft + MASK_PADDING.x / 2,
      y: spotlightRect.y - spotlightPaddingTop + MASK_PADDING.y / 2,
      width:
        spotlightRect.width +
        spotlightPaddingLeft +
        spotlightPaddingRight -
        MASK_PADDING.x,
      height:
        spotlightRect.height +
        spotlightPaddingTop +
        spotlightPaddingBottom -
        MASK_PADDING.y,
    }

    return {
      ...DEFAULT_OVERLAY,
      top: maskRect.y - Y_OFFSET,
      maskImage: `url('data:image/svg+xml;utf8, 
        <svg xmlns="http://www.w3.org/2000/svg"> 
          <rect 
            style="filter: blur(4px)"
            width="${maskRect.width}px" 
            height="${maskRect.height}px" 
            x="${maskRect.x}px" 
            y="${Y_OFFSET}px" 
            rx="24"
            ry="24"
          />
        </svg>
      '), linear-gradient(#fff,#fff)`.replace(/\r?\n|\r/g, ''),
      maskComposite: 'exclude',
    }
  }, [
    spotlightPadding?.bottom,
    spotlightPadding?.left,
    spotlightPadding?.right,
    spotlightPadding?.top,
    spotlightRect,
  ])

  return (
    <div ref={ref} className={className} {...forwardedProps}>
      {spotlightOverlay && (
        <div data-testid="spotlight-overlay" style={spotlightOverlay} />
      )}
      {children}
    </div>
  )
})
