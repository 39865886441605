import { CatalogMoney } from '@npco/mp-gql-types'
import { MultiSelectAdaptiveProps } from 'design-system/Components/MultiSelect'

export const ITEM_ADD_NEW_ITEM_FIELD = 'addNewOnSave'
export const ITEM_ATTRIBUTE_SETS_FIELD = 'attributeSets'
export const ITEM_AVAILABILITY_FIELD = 'available'
export const ITEM_MODIFIER_SETS_FIELD = 'modifierSets'
export const ITEM_MODIFIER_SETS_SELECT_FIELD = 'modifierSetsSelect'
export const ITEM_CATEGORIES_FIELD = 'categories'
export const ITEM_DESCRIPTION_FIELD = 'description'
export const ITEM_DISPLAY_LABEL_FIELD = 'displayLabel'
export const ITEM_GTIN_FIELD = 'gtin'
export const ITEM_INVOICES_ENABLED_FIELD = 'invoicesEnabled'
export const ITEM_IS_LABEL_INTERSECTING_FIELD = 'isLabelIntersecting'
export const ITEM_IS_TAX_INCLUSIVE = 'isTaxInclusive'
export const ITEM_LABEL_FIELD = 'label'
export const ITEM_NAME_FIELD = 'name'
export const ITEM_IMAGES_FIELD = 'images'
export const ITEM_PRICE_FIELD = 'price'
export const ITEM_REPORTING_CATEGORY_FIELD = 'reportingCategory'
export const ITEM_SKU_FIELD = 'sku'
export const ITEM_TAX_APPLICABLE_FIELD = 'taxApplicable'
export const STATUS_SHOULD_SYNC_LABEL_PROPERTY = 'shouldSyncLabel'
export const ITEM_VARIANTS_FIELD = 'variants'
export const ITEM_SITES_FIELD = 'sites'

export const NEW_ATTRIBUTE_VALUE_EXISTING_VALUES = 'existingValues'
export const NEW_ATTRIBUTE_VALUE_NAME = 'attributeName'

export interface CatalogueCategoryFormField {
  label: string
  value: string
}

export enum ItemTaxApplicableItems {
  NoTax = 'NoTax',
  TaxApplicable = 'TaxApplicable',
}

export type ItemAttributeValueField = {
  id: string
  value: string
  selected: boolean
}

export type ItemAttributeSetField = {
  id: string
  name: string
  attributes: ItemAttributeValueField[]
}

export type ItemModifierValueField = {
  id: string
  name: string
  price: CatalogMoney
  selected: boolean
}

export type ItemModifierSetField = {
  id: string
  name: string
  modifiers: ItemModifierValueField[]
}

export type ItemVariantsField = {
  id: string
  attributeValueUuids: string[]
  image?: string
  name: string
  sku?: string
  gtin?: string
  price: string
  isAvailable: boolean
}

export type CatalogueItemFormFields = {
  [ITEM_ADD_NEW_ITEM_FIELD]?: boolean
  [ITEM_ATTRIBUTE_SETS_FIELD]: ItemAttributeSetField[]
  [ITEM_AVAILABILITY_FIELD]: boolean
  [ITEM_MODIFIER_SETS_FIELD]: ItemModifierSetField[]
  [ITEM_MODIFIER_SETS_SELECT_FIELD]: string[]
  [ITEM_CATEGORIES_FIELD]: string[]
  [ITEM_DESCRIPTION_FIELD]: string
  [ITEM_DISPLAY_LABEL_FIELD]: boolean
  [ITEM_GTIN_FIELD]: string
  [ITEM_INVOICES_ENABLED_FIELD]: boolean
  [ITEM_IS_LABEL_INTERSECTING_FIELD]: boolean
  [ITEM_IS_TAX_INCLUSIVE]: boolean
  [ITEM_LABEL_FIELD]: string
  [ITEM_NAME_FIELD]: string
  [ITEM_IMAGES_FIELD]: {
    id: string
    url: string
  }[]
  [ITEM_PRICE_FIELD]: string
  [ITEM_REPORTING_CATEGORY_FIELD]: string[]
  [ITEM_SKU_FIELD]?: string
  [ITEM_TAX_APPLICABLE_FIELD]: ItemTaxApplicableItems
  [ITEM_ATTRIBUTE_SETS_FIELD]: ItemAttributeSetField[]
  [ITEM_VARIANTS_FIELD]: ItemVariantsField[]
  [ITEM_SITES_FIELD]: MultiSelectAdaptiveProps['value']
}

export type NewAttributeValueFields = {
  [id: string]: {
    [NEW_ATTRIBUTE_VALUE_EXISTING_VALUES]: string[]
    [NEW_ATTRIBUTE_VALUE_NAME]?: string
  }
}

export type InputComboboxAttributeSet = {
  id: string
  value: string
  label: string
  attributes: string
}
