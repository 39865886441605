import { useCallback, useEffect, useMemo } from 'react'
import { NetworkStatus, useQuery } from '@apollo/client'
import { rvEntityDetails } from '@npco/mp-utils-selected-entity'

import {
  CardSingleQuery,
  CardSingleQueryResponse,
  CardSingleQueryVariables,
} from './graphql/CardSingleQuery.generated'
import { CardSingleSubscription } from './graphql/CardSingleSubscription'
import {
  CardSingleSubscriptionResponse,
  CardSingleSubscriptionVariables,
} from './graphql/CardSingleSubscription.generated'
import { getIsCardNotFoundError } from './useCardSingleData.utils'

interface UseCardSingleDataProps {
  cardId: string
  onNotFound: () => void
}

export const useCardSingleData = ({
  cardId,
  onNotFound,
}: UseCardSingleDataProps) => {
  const { data, loading, error, refetch, networkStatus, subscribeToMore } =
    useQuery<CardSingleQueryResponse, CardSingleQueryVariables>(
      CardSingleQuery,
      {
        variables: { cardId },
        onError: (err) => {
          if (
            err.graphQLErrors.length === 1 &&
            getIsCardNotFoundError(err.graphQLErrors[0])
          ) {
            onNotFound()
          }
        },
        notifyOnNetworkStatusChange: true,
        errorPolicy: 'all',
      }
    )

  const refetchCardSingleData = useCallback(() => refetch(), [refetch])

  useEffect(() => {
    const entityUuid = rvEntityDetails().id

    if (entityUuid) {
      subscribeToMore<
        CardSingleSubscriptionResponse,
        CardSingleSubscriptionVariables
      >({
        document: CardSingleSubscription,
        variables: {
          entityUuid,
        },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data?.onDebitCardUpdate?.id) {
            return prev
          }

          if (subscriptionData.data.onDebitCardUpdate.id !== cardId) {
            return prev
          }

          const cardUpdate = subscriptionData.data.onDebitCardUpdate

          return {
            ...prev,
            getDebitCardV2: {
              ...prev.getDebitCardV2,
              ...cardUpdate,
              velocityControl: cardUpdate.velocityControl
                ? {
                    ...cardUpdate.velocityControl,
                    isPendingSubscriptionUpdate: false,
                  }
                : null,
              __typename: 'DebitCardV2',
            },
          }
        },
      })
    }

    // Adding subscribeToMore dependency causes double processing of subscription updates
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const card = useMemo(() => {
    return data?.getDebitCardV2 ?? null
  }, [data?.getDebitCardV2])

  const entity = useMemo(() => {
    return data?.getEntity ?? null
  }, [data?.getEntity])

  return {
    card,
    entity,
    isLoading: loading || networkStatus === NetworkStatus.refetch,
    error,
    refetchCardSingleData,
  }
}
