import { useEffect, useMemo, useState } from 'react'
import { useTranslations } from '@npco/utils-translations'
import { InfoBox, INFOBOX_VARIANT } from '@npco/zeller-design-system'
import { useFormikContext } from 'formik'

import { ItemsSettingsFormFields } from '../ItemsSettingsForm.types'
import { gstDisplayInfoboxtranslations } from './GstDisplayInfobox.i18n'

export const GstDisplayInfobox = () => {
  const t = useTranslations(gstDisplayInfoboxtranslations)

  const { values, dirty, touched } = useFormikContext<ItemsSettingsFormFields>()
  const [hasChanged, setHasChanged] = useState(false)

  useEffect(() => {
    if (touched.isTaxInclusive || dirty) {
      setHasChanged(true)
      return
    }

    setHasChanged(false)
  }, [touched.isTaxInclusive, dirty])

  const infoMessage = useMemo(() => {
    return hasChanged
      ? t('gstDirtyMessage', {
          GSTStatusIndicator: values.isTaxInclusive
            ? t('included')
            : t('excluded'),
        })
      : t('gstDefaultMessage')
  }, [hasChanged, t, values.isTaxInclusive])

  return (
    <InfoBox
      variant={hasChanged ? INFOBOX_VARIANT.NEGATIVE : INFOBOX_VARIANT.NEUTRAL}
    >
      <InfoBox.Message>{infoMessage}</InfoBox.Message>
    </InfoBox>
  )
}
