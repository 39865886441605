import { OnSubscriptionDataOptions, useApolloClient } from '@apollo/client'
import { ItemStatus } from '@npco/mp-gql-types'
import {
  GetEntity,
  GetEntityQueryResponse,
} from '@npco/mp-utils-selected-entity'

import { useSubscription } from 'hooks/useSubscription'

import { GetItemManagementLocalState } from '../../../graphql/getItemManagementLocalState'
import { CacheLocalStateItemManagement } from '../../../ItemManagement.types'
import { DEFAULT_GET_LIMIT } from '../../../ItemManagement.utils'
import {
  GetItemsTableCoreFieldsDoc,
  GetItemsTableCoreFieldsFragment,
} from './graphql/getItemsTableCoreFields.generated'
import {
  GetItemsTable,
  GetItemsTableQueryResponse,
} from './graphql/getItemsTableQuery.generated'
import {
  GetItemsTableSubscription,
  GetItemsTableSubscriptionResponse,
  GetItemsTableSubscriptionVariables,
} from './graphql/getItemsTableSubscription.generated'

export const onGetTableItemsSubscriptionData = (
  payload: OnSubscriptionDataOptions<GetItemsTableSubscriptionResponse>
) => {
  const { client: c, subscriptionData } = payload
  const data = subscriptionData?.data?.onItemUpdate

  if (!data?.items) {
    return
  }

  const localCache = c.readQuery<CacheLocalStateItemManagement>({
    query: GetItemManagementLocalState,
  })

  const filter = localCache?.local.itemManagement.items.filterInput ?? null

  const cachedItemsResponse = c.readQuery<GetItemsTableQueryResponse>({
    query: GetItemsTable,
    variables: {
      filter,
      limit: DEFAULT_GET_LIMIT,
    },
  })

  const cachedItems = cachedItemsResponse?.getItems?.items ?? []
  const nextToken = cachedItemsResponse?.getItems?.nextToken ?? null

  const itemsToAddInCache: GetItemsTableCoreFieldsFragment[] = []

  data.items
    // Do not include deleted item events
    .filter((item) => item.status !== ItemStatus.DELETED)
    .forEach((itemUpdate) => {
      const cachedItem = c.readFragment<GetItemsTableCoreFieldsFragment>({
        id: `Item:${itemUpdate.id}`,
        fragment: GetItemsTableCoreFieldsDoc,
        fragmentName: 'GetItemsTableCoreFields',
      })

      // NOTE: a newly created or updated item will appear in the cache
      // immediately as response is stored before on subscription data
      if (cachedItem) {
        const itemExistsInQuery = cachedItems.some(
          (item) => item?.id === cachedItem?.id
        )

        // NOTE: only write to the list query if the item doesn't exist
        if (!itemExistsInQuery) {
          itemsToAddInCache.push(cachedItem)
        }
      }
    })

  if (itemsToAddInCache.length) {
    c.writeQuery<GetItemsTableQueryResponse>({
      query: GetItemsTable,
      variables: {
        filter,
        limit: DEFAULT_GET_LIMIT,
      },
      data: {
        getItems: {
          // NOTE: Always add at the top of the table for now
          // https://npco-dev.atlassian.net/browse/ZD-15369 task to
          // implement filtering and sorting on items update
          items: [...itemsToAddInCache, ...cachedItems],
          nextToken,
        },
      },
    })
  }
}

export const useGetItemsTableSubscription = () => {
  const client = useApolloClient()

  // NOTE: entity data should already be in the cache however if not
  // subscription below will be skipped
  const entityResponse = client.cache.readQuery<GetEntityQueryResponse>({
    query: GetEntity,
  })

  const entityUuid = entityResponse?.getEntity?.id

  const data = useSubscription<
    GetItemsTableSubscriptionResponse,
    GetItemsTableSubscriptionVariables
  >(GetItemsTableSubscription, {
    onSubscriptionData: onGetTableItemsSubscriptionData,
    variables: entityUuid ? { entityUuid } : undefined,
    skip: !entityUuid,
  })

  return data
}
