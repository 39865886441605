import { useCallback, useState } from 'react'
import { ApolloError } from '@apollo/client'
import { OnboardingStatus } from '@npco/mp-gql-types'
import { rvEntityDetails } from '@npco/mp-utils-selected-entity'
import { showApiErrorToast } from '@npco/zeller-design-system'

import { now } from 'utils/dayjs'
import { ServerError } from 'types/errors'

import { useSaveEntityOnboardingErrorsMutation } from './graphql/saveEntityOnboardingErrors.generated'

export const useTrackOnboardingErrors = () => {
  const [failedDataPayload, setFailedDataPayload] = useState('')

  const [saveErrors] = useSaveEntityOnboardingErrorsMutation({
    onError: (err) => {
      showApiErrorToast(err)
    },
  })

  const trackOnboardingErrors = useCallback(
    (error: ApolloError, onboardingStatus?: OnboardingStatus) => {
      saveErrors({
        variables: {
          input: error.graphQLErrors?.map((currentError: ServerError) => ({
            onboardingStatus:
              onboardingStatus ??
              (rvEntityDetails().onboardingStatus as OnboardingStatus),
            errorMessage: error.message,
            errorType: currentError.errorType ?? '',
            data: failedDataPayload,
            timestamp: now().toISOString(),
          })),
        },
      })
    },
    [saveErrors, failedDataPayload]
  )

  const setTrackOnboardingErrorsDataPayload = useCallback(
    (payload: unknown) => {
      setFailedDataPayload(JSON.stringify(payload))
    },
    []
  )

  return {
    trackOnboardingErrors,
    setTrackOnboardingErrorsDataPayload,
  }
}
