import { useReactiveVar } from '@apollo/client'
import { rvEntityDetails } from '@npco/mp-utils-selected-entity'
import { rvSimBillingAccount } from 'apps/component-merchant-portal/src/graphql/reactiveVariables/simBillingAccount'
import { ModalWithButtons } from 'design-system/Components/Modal'
import { useModalState } from 'design-system/Components/Modal/hooks/useModalState'
import { ModalBasic } from 'design-system/Components/Modal/ModalBasic/ModalBasic'

import { translate } from 'utils/translations'
import { ThirdPartyBankAccount } from 'types/accounts'
import { page, shared } from 'translations'

import { BlockDeleteAccountModal } from './BlockDeleteAccountModal/BlockDeleteAccountModal'
import { useDeleteCustomer } from './DeleteAccountModal.hooks'
import {
  StyledDeleteButton,
  StyledDeleteButtonContainer,
  StyledDeleteModalContent,
} from './DeleteAccountModal.styled'

export const DeleteAccountModal = ({
  selectedAccount,
  redirectOnDeleteSuccess,
}: {
  selectedAccount: ThirdPartyBankAccount
  redirectOnDeleteSuccess: () => void
}) => {
  const { isModalOpen, openModal, closeModal } = useModalState()
  const entityDetails = useReactiveVar(rvEntityDetails)
  const simBillingAccount = useReactiveVar(rvSimBillingAccount)

  const {
    isModalOpen: isBlockAccountModalOpen,
    openModal: openBlockAccountModal,
    closeModal: closeBlockAccountModal,
  } = useModalState()

  const onDeleteSuccess = () => {
    closeModal()
    redirectOnDeleteSuccess()
  }

  const { deleteExternalAccount, loadingDeleteExternalAccount } =
    useDeleteCustomer(onDeleteSuccess, selectedAccount.id)

  const isAccountForDeposit = () => {
    return (
      selectedAccount.id === entityDetails.depositAccountUuid &&
      !entityDetails.remitToCard
    )
  }

  const isAccountForBilling = () => {
    return simBillingAccount && selectedAccount.id === simBillingAccount.id
  }

  const onSubmitDeleteAccount = () => {
    if (isAccountForDeposit() || isAccountForBilling()) {
      openBlockAccountModal()
      return
    }

    openModal()
  }

  return (
    <>
      <StyledDeleteButtonContainer>
        <StyledDeleteButton onClick={onSubmitDeleteAccount}>
          {translate('page.settings.deposits.deleteAccount.buttonText')}
        </StyledDeleteButton>
      </StyledDeleteButtonContainer>
      <ModalWithButtons
        primaryButtonLabel={
          page.settings.deposits.deleteAccount.primaryButtonText
        }
        secondaryButtonLabel={shared.cancel}
        hasCancelButton
        title={page.settings.deposits.deleteAccount.modalTitle}
        isPrimaryButtonDisabled={loadingDeleteExternalAccount}
        isPrimaryButtonLoading={loadingDeleteExternalAccount}
        onConfirm={deleteExternalAccount}
        onCancel={closeModal}
        isOpen={isModalOpen}
      >
        <StyledDeleteModalContent>
          {translate('page.settings.deposits.deleteAccount.description', {
            accountName: selectedAccount.accountName,
          })}
        </StyledDeleteModalContent>
      </ModalWithButtons>
      <ModalBasic
        isOpen={isBlockAccountModalOpen}
        onCancel={closeBlockAccountModal}
      >
        <BlockDeleteAccountModal
          description={
            isAccountForBilling()
              ? page.settings.deposits.deleteAccount.blockDescriptionForSim
              : page.settings.deposits.deleteAccount.blockDescription
          }
          closeModal={closeBlockAccountModal}
        />
      </ModalBasic>
    </>
  )
}
