import { useLocation } from 'react-router-dom-v5-compat'
import { useReactiveVar } from '@apollo/client'
import { rvEntityDetails } from '@npco/mp-utils-selected-entity'
import { rvOnboardingIsProcessing } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'

import {
  SAVE_AND_CLOSE_ALWAYS_ALLOWED_ROUTES,
  SAVE_AND_CLOSE_DISALLOWED_ROUTES,
} from 'const/onboarding'

export const useSaveAndCloseGroup = () => {
  const { pathname } = useLocation()
  const entityDetails = useReactiveVar(rvEntityDetails)
  const isOnboardingProcessing = useReactiveVar(rvOnboardingIsProcessing)

  const isSaveAndCloseAllowed = () => {
    if (SAVE_AND_CLOSE_ALWAYS_ALLOWED_ROUTES.includes(pathname)) {
      return true
    }

    if (
      entityDetails.id &&
      !SAVE_AND_CLOSE_DISALLOWED_ROUTES.includes(pathname)
    ) {
      return true
    }

    return false
  }

  return {
    isSaveAndCloseAllowed: isSaveAndCloseAllowed(),
    isOnboardingProcessing,
  }
}
