import { SelectStyle } from '@npco/zeller-design-system'
import { Placement } from '@popperjs/core'

import { SelectSize } from '../Select'

export interface MultiSelectItemBasic {
  value: string
  label: string
  sublabel?: string
  isDisabled?: boolean
}

export interface MultiSelectTopSectionRenderProps {
  onClose: () => void
  isOpen: boolean
}

export interface MultiSelectBottomSectionRenderProps {
  isOpen: boolean
  onClick: () => void
  onClose: () => void
}
export interface MultiSelectNoItemsPlaceholderRenderProps {
  closeMenu: () => void
}

export const ALL_VALUE = 'ALL'

export interface MultiSelectTriggerRenderProps {
  ['aria-expanded']?: boolean
  ['aria-haspopup']?: 'listbox'
  ['aria-labelledby']?: string
  id: string
  onClick: () => void
  ref?: React.RefCallback<HTMLButtonElement | null>
  isOpen: boolean
  disabled?: boolean
  hasError?: boolean
  size?: SelectSize
  selectStyle?: SelectStyle
}

export interface MultiSelectItemRenderProps<ItemType> {
  ['aria-selected']?: boolean
  id: string
  key: string
  onClick: React.MouseEventHandler<HTMLLIElement>
  ref?: React.RefObject<HTMLLIElement>
  role?: 'option'
  item: ItemType
  isChecked: boolean
  label: React.ReactNode
  className?: string
  selectStyle?: SelectStyle
  /**
   * Closes the dropdown menu.
   */
  close?: () => void
}

export interface MultiSelectLabelRenderProps {
  id: string
  htmlFor: string
}

export interface MultiSelectAdditionalControlsRenderProps {
  closeMenu: () => void
}

export interface MultiSelectBasicProps<ItemType> {
  items: ItemType[]
  mobileLabel?: string
  selectedItems: ItemType[]
  allItemsLabel?: string
  selectStyle: SelectStyle
  selectSize: SelectSize
  onClose?: (selectedItems: ItemType[]) => void
  onChange: (selectedItems: ItemType[]) => void
  renderTopSection?: (
    renderProps: MultiSelectTopSectionRenderProps
  ) => React.ReactNode
  renderBottomSection?: (
    renderProps: MultiSelectBottomSectionRenderProps
  ) => React.ReactNode
  renderAdditionalControls?: (
    renderProps: MultiSelectAdditionalControlsRenderProps
  ) => React.ReactNode
  renderLabel?: (renderProps: MultiSelectLabelRenderProps) => React.ReactNode
  renderTrigger: (renderProps: MultiSelectTriggerRenderProps) => React.ReactNode
  renderItem: (
    renderProps: MultiSelectItemRenderProps<ItemType>
  ) => React.ReactNode
  renderNoItemsPlaceholder?: (
    renderProps: MultiSelectNoItemsPlaceholderRenderProps
  ) => React.ReactNode
  isDisabled?: boolean
  hasError?: boolean
  popperWidth?: string
  maxHeight?: string
  shouldAutoFocusControl?: boolean
  popperPlacement?: Placement
  onScrollAtBottom?: () => void
  isMobileResolution?: boolean
  mobileOptionsListHeight?: string
}
