import { useCallback } from 'react'
import { ApolloCache, useReactiveVar } from '@apollo/client'
import { rvEntityDetails } from '@npco/mp-utils-selected-entity'
import { rvSelectedAccountTransaction } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'

import {
  useDebitCardTransactionUpdate,
  useSelectedTransactionUpdate,
} from 'hooks/transactions'

import { updateOutstandingCountCallback } from '../../utils/updateOutstandingTransactionsCount'
import { OutstandingSettingsType } from '../useOutstandingFilterSettings'
import { useRemoveUpdatedOutstandingTransaction } from '../useUpdatedOutstandingTransactions/useRemoveUpdatedOutstandingTransaction'
import { DebitCardTransactionUpdateFragment as DebitCardTransactionUpdate } from './graphql/debitCardTransactionUpdateFragment.generated'
import { useDebitCardTransactionUpdateV2Subscription } from './graphql/subscribeDebitCardTransactionUpdate.generated'
import { DebitCardTransaction } from './useDebitCardTransactions.types'

interface UseSubscribeDebitCardTransactionProps {
  debitCardId: string
  transactions: DebitCardTransaction[]
  skip?: boolean
  outstandingSettings: OutstandingSettingsType
}

export const useSubscribeDebitCardTransaction = ({
  debitCardId,
  transactions,
  skip,
  outstandingSettings,
}: UseSubscribeDebitCardTransactionProps) => {
  const selectedTransaction = useReactiveVar(rvSelectedAccountTransaction)

  const { removeUpdatedOutstandingTransaction } =
    useRemoveUpdatedOutstandingTransaction()
  const { updateSelectedTransactionWithSubscribeData } =
    useSelectedTransactionUpdate({ cachedTransactions: transactions })
  const { updateTransactionWithSubscribeData } = useDebitCardTransactionUpdate({
    cachedTransactions: transactions,
  })

  const handleSubscriptionUpdate = useCallback(
    (
      transactionUpdate: DebitCardTransactionUpdate,
      cache: ApolloCache<object>
    ) => {
      if (selectedTransaction?.id === transactionUpdate.id) {
        updateSelectedTransactionWithSubscribeData(
          transactionUpdate,
          updateOutstandingCountCallback(cache, outstandingSettings)
        )
      } else {
        updateTransactionWithSubscribeData(
          transactionUpdate,
          updateOutstandingCountCallback(cache, outstandingSettings)
        )
      }
      removeUpdatedOutstandingTransaction(transactionUpdate.id)
    },
    [
      updateSelectedTransactionWithSubscribeData,
      updateTransactionWithSubscribeData,
      removeUpdatedOutstandingTransaction,
      selectedTransaction,
      outstandingSettings,
    ]
  )

  useDebitCardTransactionUpdateV2Subscription({
    onData: ({ data: subscriptionResult, client }) => {
      if (
        debitCardId ===
        subscriptionResult.data?.onDebitCardTransactionUpdate?.debitCardId
      ) {
        handleSubscriptionUpdate(
          subscriptionResult.data.onDebitCardTransactionUpdate,
          client.cache
        )
      }
    },
    variables: {
      entityUuid: rvEntityDetails()?.id,
    },
    skip,
  })
}
