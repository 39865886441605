import { DocumentType } from '@npco/mp-gql-types'
import { rvCurrentUserData } from '@npco/mp-utils-logged-in-user'
import { EntityDetails, rvEntityDetails } from '@npco/mp-utils-selected-entity'
import {
  rvHasSeenPushAppModal,
  rvIsUserSigningUp,
  rvKYCData,
  rvLastSelectedDates,
  rvOnboardingInitialSearchData,
  rvOnboardingMetadata,
  rvPathBeforeLoggingIn,
  rvSelectedAccountTransaction,
  rvSelectedSite,
} from 'apps/component-merchant-portal/src/graphql/reactiveVariables'
import { rvIsE2eInProgress } from 'apps/component-merchant-portal/src/graphql/reactiveVariables/auth'

import { lastStoredTimeFilterInitialState } from 'const/common'
import { KYCData } from 'types/kyc'
import {
  OnboardingInitialSearchData,
  OnboardingMetadata,
} from 'types/onboarding'
import { SESSION_STORAGE_KEYS } from 'services/sessionStorage/keys'
import { SessionStorageHandlerSlice } from 'services/sessionStorage/SessionStorageHandlerSlice'
import { rvHasSeenOverviewBanner } from 'pages/Dashboard/DashboardLayout/reactiveVariables'

export const AppStorageHandler = () => {
  return (
    <>
      <SessionStorageHandlerSlice<boolean>
        reactiveVarHandler={rvIsUserSigningUp}
        storageKey={SESSION_STORAGE_KEYS.IS_SIGNING_UP}
        defaultValue={false}
      />
      <SessionStorageHandlerSlice<OnboardingInitialSearchData>
        reactiveVarHandler={rvOnboardingInitialSearchData}
        storageKey={SESSION_STORAGE_KEYS.ONBOARDING_INITIAL_SEARCH_DATA}
        defaultValue={{}}
      />
      <SessionStorageHandlerSlice<OnboardingMetadata>
        reactiveVarHandler={rvOnboardingMetadata}
        storageKey={SESSION_STORAGE_KEYS.ONBOARDING_METADATA}
        defaultValue={{}}
      />
      <SessionStorageHandlerSlice
        reactiveVarHandler={rvCurrentUserData}
        storageKey={SESSION_STORAGE_KEYS.CURRENT_USER_DATA}
        defaultValue={null}
      />
      <SessionStorageHandlerSlice
        reactiveVarHandler={rvSelectedSite}
        storageKey={SESSION_STORAGE_KEYS.SITES_LAST_SELECTED_SITE_ID}
        defaultValue=""
      />
      <SessionStorageHandlerSlice
        reactiveVarHandler={rvPathBeforeLoggingIn}
        storageKey={SESSION_STORAGE_KEYS.PREVIOUS_PATH}
        defaultValue=""
      />
      <SessionStorageHandlerSlice
        reactiveVarHandler={rvEntityDetails}
        storageKey={SESSION_STORAGE_KEYS.ENTITY_DETAILS}
        defaultValue={{} as EntityDetails}
      />
      <SessionStorageHandlerSlice
        reactiveVarHandler={rvLastSelectedDates}
        storageKey={SESSION_STORAGE_KEYS.FILTER_SELECTED_DATES}
        defaultValue={lastStoredTimeFilterInitialState}
      />
      <SessionStorageHandlerSlice
        reactiveVarHandler={rvSelectedAccountTransaction}
        storageKey={SESSION_STORAGE_KEYS.ACCOUNTS_LAST_SELECTED_TRANSACTION}
        defaultValue={null}
      />
      <SessionStorageHandlerSlice<boolean>
        reactiveVarHandler={rvIsE2eInProgress}
        storageKey={SESSION_STORAGE_KEYS.AUTH_E2E_IN_PROGRESS}
        defaultValue={false}
      />
      <SessionStorageHandlerSlice<boolean>
        reactiveVarHandler={rvHasSeenPushAppModal}
        storageKey={SESSION_STORAGE_KEYS.HAS_SEEN_PUSH_APP_MODAL}
        defaultValue={false}
      />
      <SessionStorageHandlerSlice<boolean>
        reactiveVarHandler={rvHasSeenOverviewBanner}
        storageKey={SESSION_STORAGE_KEYS.HAS_SEEN_OVERIVEW_BANNER}
        defaultValue={false}
      />
      <SessionStorageHandlerSlice<KYCData>
        reactiveVarHandler={rvKYCData}
        storageKey={SESSION_STORAGE_KEYS.KYC_DATA}
        defaultValue={{
          attempts: {
            [DocumentType.DRIVING_LICENCE]: 0,
            [DocumentType.MEDICARE_CARD]: 0,
            [DocumentType.PASSPORT]: 0,
          },
        }}
      />
    </>
  )
}
