import { useCallback, useEffect } from 'react'
import { CustomerRole, EntityType } from '@npco/mp-gql-types'
import { useLoggedInUser } from '@npco/mp-utils-logged-in-user'
import { rvEntityDetails } from '@npco/mp-utils-selected-entity'
import { useCreateCustomer } from 'auth/useCreateCustomer'
import { useFetchSetupData } from 'auth/useFetchSetupData'

import { useConfigData } from 'hooks/useConfigData'
import { useAnalyticsLogger } from 'services/Analytics/useAnalyticsLogger'

import { AnalyticsEventNames } from '../services/Analytics/events'
import { useZellerAuthenticationContext } from './ZellerAuthenticationContext'

export const useSetup = () => {
  const { idTokenClaims, getNewToken, fetchTokenClaims } =
    useZellerAuthenticationContext()
  const { identifyAnalyticsUser, trackAnalyticsEvent } = useAnalyticsLogger()

  const {
    fetchSetupData,
    isFetchSetupDataCalled,
    fetchSetupDataError,
    isFetchSetupDataLoading,
  } = useFetchSetupData()
  useConfigData()

  const { createCustomer, isCreateCustomerCalled } = useCreateCustomer()

  const { userData, setUserData } = useLoggedInUser()

  const createNewCustomer = useCallback(async () => {
    const { data: responseData } = await createCustomer({
      variables: {
        input: [
          {
            email: idTokenClaims?.email,
          },
        ],
      },
    })

    const customerUuid = responseData?.createCustomer?.[0].id as string
    const responseEntityUuid = responseData?.createCustomer?.[0]
      .entityUuid as string
    const role = CustomerRole.ADMIN
    rvEntityDetails({
      ...rvEntityDetails(),
      id: responseEntityUuid,
    })
    setUserData({
      id: customerUuid,
      entityUuid: responseEntityUuid,
      email: idTokenClaims?.email,
      role,
      type: EntityType.INDIVIDUAL,
      productTourStatus: {
        showAdminMerchantPortalWelcome: true,
        showCorporateCardsWalkthrough: Boolean(
          userData?.productTourStatus?.showCorporateCardsWalkthrough
        ),
        showCorporateCardsSettingsWalkthrough: Boolean(
          userData?.productTourStatus?.showCorporateCardsSettingsWalkthrough
        ),
        showInvoiceInstructions: Boolean(
          userData?.productTourStatus?.showInvoiceInstructions
        ),
        showInvoiceSendViaInfo: Boolean(
          userData?.productTourStatus?.showInvoiceSendViaInfo
        ),
        showInvoicesCustomisationWelcome: Boolean(
          userData?.productTourStatus?.showInvoicesCustomisationWelcome
        ),
        showInvoicesScheduleSendWelcome: Boolean(
          userData?.productTourStatus?.showInvoicesScheduleSendWelcome
        ),
        showInvoicesSendBySmsWelcome: Boolean(
          userData?.productTourStatus?.showInvoicesSendBySmsWelcome
        ),
        showInvoicesWelcome: Boolean(
          userData?.productTourStatus?.showInvoicesWelcome
        ),
        showInvoicingCustomisationSettingsWelcome: Boolean(
          userData?.productTourStatus?.showInvoicingCustomisationSettingsWelcome
        ),
        showItemInstructions: Boolean(
          userData?.productTourStatus?.showItemInstructions
        ),
        showNotificationsWelcome: Boolean(
          userData?.productTourStatus?.showNotificationsWelcome
        ),
        showTapToPayInstructions: Boolean(
          userData?.productTourStatus?.showTapToPayInstructions
        ),
        // If no user data, default to true as onboarding users are routed to shop
        showOnboardingShop:
          userData?.productTourStatus?.showOnboardingShop !== undefined
            ? Boolean(userData?.productTourStatus?.showOnboardingShop)
            : true,
        showCatalogueItemsWelcome: Boolean(
          userData?.productTourStatus?.showCatalogueItemsWelcome
        ),
      },
    })

    identifyAnalyticsUser(customerUuid, {
      entityId: responseEntityUuid,
      role,
    })
    trackAnalyticsEvent(AnalyticsEventNames.ONBOARDING_SIGNUP)
  }, [
    identifyAnalyticsUser,
    createCustomer,
    idTokenClaims?.email,
    userData?.productTourStatus,
    setUserData,
    trackAnalyticsEvent,
  ])

  const init = useCallback(async () => {
    // check if idTokenClaims['url'] is an empty object
    const isMetadataEmpty =
      idTokenClaims?.['https://identity.myzeller.com/user_metadata'] &&
      Object.keys(
        idTokenClaims?.['https://identity.myzeller.com/user_metadata']
      ).length === 0

    if (!idTokenClaims || isCreateCustomerCalled) {
      return
    }

    if (isMetadataEmpty) {
      await createNewCustomer()

      getNewToken(true)
      await fetchTokenClaims()
    } else if (!isFetchSetupDataCalled) {
      await fetchSetupData()
    }
  }, [
    createNewCustomer,
    fetchSetupData,
    getNewToken,
    idTokenClaims,
    isCreateCustomerCalled,
    isFetchSetupDataCalled,
    fetchTokenClaims,
  ])

  useEffect(() => {
    init().catch(() => undefined)
  }, [init])

  return {
    isFetchSetupDataLoading,
    fetchSetupDataError,
  }
}
