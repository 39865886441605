import { COLOR } from '@npco/zeller-design-system'

export const ITEM_CATEGORY_MIN = 1
export const ITEM_DESCRIPTION_MAX_CHARACTERS = 1000
export const ITEM_MAX_AMOUNT = 50000
export const ITEM_MIN_AMOUNT = 0
export const ITEM_NAME_MAX_CHARACTERS = 75

export const ITEM_CATEGORIES_FIELD = 'categories'
export const ITEM_DESCRIPTION_FIELD = 'description'
export const ITEM_IS_TAX_INCLUSIVE = 'isTaxInclusive'
export const ITEM_NAME_FIELD = 'name'
export const ITEM_PRICE_FIELD = 'price'
export const ITEM_SKU_FIELD = 'sku'
export const ITEM_TAX_APPLICABLE_FIELD = 'taxApplicable'
export const ITEM_ADD_NEW_ITEM_FIELD = 'addNewOnSave'

export const ITEM_DEFAULT_PRICE = '0.00'
export const ITEM_TAX_RATE = 10

export const ITEM_CATEGORY_COLOURS = [
  COLOR.POS_DARK_BLUE,
  COLOR.POS_MID_BLUE,
  COLOR.POS_LIGHT_BLUE,
  COLOR.POS_DARK_GREEN,
  COLOR.POS_MID_GREEN,
  COLOR.POS_LIGHT_GREEN,
  COLOR.POS_DARK_ORANGE,
  COLOR.POS_MID_ORANGE,
  COLOR.POS_LIGHT_ORANGE,
]
