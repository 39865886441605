import { useMultiEntityTabs } from '@npco/mp-feature-multi-entity-panel'
import { useTranslations } from '@npco/utils-translations'
import {
  COLOR,
  DragAndDropTabs,
  PlusIcon,
  TopBar,
} from '@npco/zeller-design-system'

import { TopBarUserMenu } from './components/TopBarUserMenu/TopBarUserMenu'
import { topBarNavTranslations } from './TopBarNav.i18n'

export const TopBarNavDeps = {
  useMultiEntityTabs,
}

export const TopBarNav = () => {
  const {
    entities,
    activeEntityId,
    setSelectedEntityId,
    reorderEntities,
    closeEntitySession,
    addNewEntitySession,
  } = TopBarNavDeps.useMultiEntityTabs()

  const t = useTranslations(topBarNavTranslations)
  return (
    <TopBar>
      <TopBar.CentreSection>
        <DragAndDropTabs
          tabs={entities}
          activeTabId={activeEntityId}
          onTabsChange={reorderEntities}
          handleSelectTab={setSelectedEntityId}
          handleTabClose={closeEntitySession}
        />
      </TopBar.CentreSection>
      <TopBar.RightSection>
        <TopBar.TopBarButton
          component="button"
          activeClassName={TopBar.TopBarButton.activeClassName}
          onClick={addNewEntitySession}
          aria-label={t('addNewTab')}
        >
          <PlusIcon height="12" width="12" color={COLOR.BLACK_900} />
        </TopBar.TopBarButton>
        <TopBarUserMenu />
      </TopBar.RightSection>
    </TopBar>
  )
}
