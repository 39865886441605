import { useEffect, useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { rvEntityDetails } from '@npco/mp-utils-selected-entity'

import dayjs from 'utils/dayjs'
import {
  DebitCardTransactionAmountUpdate as DebitCardTransactionAmountUpdateResponse,
  DebitCardTransactionAmountUpdateVariables,
} from 'types/gql-types/DebitCardTransactionAmountUpdate'
import {
  TotalAccountBalanceQuery as TotalAccountBalanceQueryResponse,
  TotalAccountBalanceQueryVariables,
} from 'types/gql-types/TotalAccountBalanceQuery'
import { getTimezone } from 'pages/Dashboard/DashboardFinance/DashboardFinance.utils'
import {
  getIsApproved,
  getIsIncoming,
  getIsOutgoing,
} from 'pages/Dashboard/utils/debitCardTransactionUpdate'

import { DebitCardTransactionAmountUpdate } from './graphql/DebitCardTransactionAmountUpdate'
import { TotalAccountBalanceQuery } from './graphql/TotalAccountBalanceQuery'
import { buildBalance } from './useTotalAccountBalance.utils'
import { useTrackNewTransactions } from './useTrackNewTransactions'

export const useTotalAccountBalance = () => {
  const now = useMemo(() => dayjs(), [])
  const currentDay = useMemo(() => now.format('YYYY-MM-DD'), [now])

  const { data, loading, error, subscribeToMore } = useQuery<
    TotalAccountBalanceQueryResponse,
    TotalAccountBalanceQueryVariables
  >(TotalAccountBalanceQuery, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      range: {
        startDate: currentDay,
        endDate: currentDay,
      },
      timeZone: getTimezone().timeZone,
    },
  })

  const { getIsNew, registerTransaction } = useTrackNewTransactions({
    after: now,
  })

  useEffect(() => {
    subscribeToMore<
      DebitCardTransactionAmountUpdateResponse,
      DebitCardTransactionAmountUpdateVariables
    >({
      document: DebitCardTransactionAmountUpdate,
      variables: {
        entityUuid: rvEntityDetails().id,
      },
      updateQuery: (prev, { subscriptionData }) => {
        const subscriptionUpdate =
          subscriptionData.data.onDebitCardTransactionUpdate

        if (!subscriptionUpdate) {
          return prev
        }

        const isOutgoing = getIsOutgoing(subscriptionUpdate)
        const isApproved = getIsApproved(subscriptionUpdate)
        const isIncoming = getIsIncoming(subscriptionUpdate)
        const isNew = getIsNew(subscriptionUpdate)
        const isExternal = isOutgoing || isIncoming

        registerTransaction(subscriptionUpdate)

        if (!isExternal || !isNew || !isApproved) {
          return prev
        }

        if (prev.getDebitCardAccountBalances?.length !== 1) {
          return buildBalance(0, subscriptionUpdate)
        }

        return buildBalance(
          parseInt(prev.getDebitCardAccountBalances[0].balance.value, 10),
          subscriptionUpdate
        )
      },
    })
    // Adding dependencies causes double processing of subscription updates
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const amountInCents = useMemo(() => {
    if (data?.getDebitCardAccountBalances?.length !== 1) {
      return 0
    }

    return parseInt(data.getDebitCardAccountBalances[0].balance.value, 10)
  }, [data?.getDebitCardAccountBalances])

  return {
    amountInCents,
    isLoading: loading && !data?.getDebitCardAccountBalances?.length,
    error,
  }
}
