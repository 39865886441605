export const INVOICE_DISCOUNT_PERCENTAGE_MAX_AMOUNT = 100
export const INVOICE_DISCOUNT_PERCENTAGE_MIN_AMOUNT = 0
export const INVOICE_DISCOUNT_PRICE_MIN_AMOUNT = 0
export const INVOICE_EMAIL_MESSAGE_MAX_CHARACTERS = 1000
export const INVOICE_EMAIL_SUBJECT_MAX_CHARACTERS = 250
export const INVOICE_ITEM_CALCULATE_QUANTITY_MAX_AMOUNT = 10000
export const INVOICE_ITEM_CALCULATE_QUANTITY_MIN_AMOUNT = 1
export const INVOICE_ITEM_DESCRIPTION_MAX_CHARACTERS = 200
export const INVOICE_ITEM_DISCOUNT_PERCENTAGE_MAX_AMOUNT = 100
export const INVOICE_ITEM_DISCOUNT_PERCENTAGE_MIN_AMOUNT = 0
export const INVOICE_ITEM_DISCOUNT_PRICE_MIN_AMOUNT = 0
export const INVOICE_ITEM_MIN_AMOUNT = 0
export const INVOICE_ITEM_NAME_MAX_CHARACTERS = 75
export const INVOICE_MAX_AMOUNT_IN_CENTI_CENTS = 2000000000
export const INVOICE_MAX_AMOUNT_IN_CENTS = 20000000
export const INVOICE_MIN_AMOUNT_IN_CENTI_CENTS = 10000
export const INVOICE_MIN_AMOUNT_IN_CENTS = 100
export const INVOICE_TAX_EXCLUSIVE_RATE = 10
export const INVOICE_TAX_INCLUSIVE_RATE = 11
export const INVOICE_ITEM_TAX_RATE = 10
export const INVOICE_TITLE_HEADER_MAX_CHARACTERS = 250

export const INVOICE_DEFAULT_PERCENTAGE = '0.00'
export const INVOICE_DEFAULT_PRICE = '0.00'
export const INVOICE_DEFAULT_UNIT_QUANTITY = '1'
export const INVOICE_DEFAULT_UNIT_DAY = '1.00'
export const INVOICE_DEFAULT_UNIT_HOUR = '1.00'

export const INVOICE_PAYER_FIELD = 'customer.payerContact.contactUuid'
export const INVOICE_PAYER_STATUS = 'customer.payerContact.status'
export const INVOICE_ATTENTION_TO_FIELD =
  'customer.attentionToContact.contactUuid'
export const INVOICE_ATTENTION_TO_STATUS = 'customer.attentionToContact.status'

export const INVOICE_PAYER_EMAIL_FIELD = 'customer.emailAddress'

export const INVOICE_TITLE_HEADER_FIELD = 'title.header'
export const INVOICE_TITLE_MESSAGE_FIELD = 'title.message'

export const INVOICE_DISCOUNT_CONFIG_FIELD = 'config'
export const INVOICE_DISCOUNT_PERCENTAGE_FIELD = 'percentage'
export const INVOICE_DISCOUNT_PRICE_FIELD = 'price'

export const INVOICE_ITEMS_CALCULATE_INCLUDE_CALCULATION_FIELD =
  'includeCalculation'
export const INVOICE_ITEMS_CALCULATE_PRICE_FIELD = 'price'
export const INVOICE_ITEMS_CALCULATE_QUANTITY_FIELD = 'quantity'
export const INVOICE_ITEMS_CALCULATE_UNIT_FIELD = 'unit'
export const INVOICE_ITEMS_DISCOUNT_CONFIG_FIELD = 'config'
export const INVOICE_ITEMS_DISCOUNT_FIELD = 'discount'
export const INVOICE_ITEMS_DISCOUNT_PERCENTAGE_FIELD = 'percentage'
export const INVOICE_ITEMS_DISCOUNT_PRICE_FIELD = 'price'
export const INVOICE_ITEMS_FIELD = 'items'
export const INVOICE_ITEMS_HAS_CALCULATION_FIELD = 'hasCalculation'
export const INVOICE_ITEMS_INITIAL_CATALOG_DESCRIPTION_FIELD =
  'initialCatalogDescription'
export const INVOICE_ITEMS_DESCRIPTION_FIELD = 'description'
export const INVOICE_ITEMS_NAME_FIELD = 'name'
export const INVOICE_ITEMS_PRICE_FIELD = 'price'
export const INVOICE_ITEMS_QUANTITY_FIELD = 'quantity'
export const INVOICE_ITEMS_TAX_APPLICABLE_FIELD = 'taxApplicable'
export const INVOICE_ITEMS_UNIT_FIELD = 'unit'

export const INVOICE_TAX_INCLUSIVE_FIELD = 'itemsTaxInclusive'

export const INVOICE_SCHEDULE_INVOICE_DATE_FIELD = 'schedule.invoiceDate'
export const INVOICE_SCHEDULE_PAYMENT_TERMS_FIELD = 'schedule.paymentTerms'
export const INVOICE_SCHEDULE_DUE_DATE_FIELD = 'schedule.dueDate'
export const INVOICE_SCHEDULE_SEND_TOGGLE_FIELD = 'schedule.sendEnabled'
export const INVOICE_SCHEDULE_SEND_DATE_FIELD = 'schedule.sendDate'

export const INVOICE_EMAIL_RECIPIENT_FIELD = 'delivery.email.recipient'
export const INVOICE_EMAIL_ENABLED_FIELD = 'delivery.email.isEnabled'
export const INVOICE_SMS_RECIPIENT_FIELD = 'delivery.sms.recipient'
export const INVOICE_SMS_ENABLED_FIELD = 'delivery.sms.isEnabled'
export const INVOICE_EMAIL_CC_FIELD = 'delivery.email.cc'
export const INVOICE_EMAIL_BCC_FIELD = 'delivery.email.bcc'
export const INVOICE_EMAIL_SUBJECT_FIELD = 'delivery.email.subject'
export const INVOICE_EMAIL_MESSAGE_FIELD = 'delivery.email.message'
export const INVOICE_EMAIL_SEND_ME_COPY_FIELD = 'delivery.email.sendMeCopy'

export const VALID_NUMBER_INTEGER_STRING_REGEX = /^\d+$/
export const VALID_NUMBER_STRING_REGEX = /^\d+(?:(\.|\.\d{1,2}))?$/

export const INVOICE_FORM_CONTAINER = 'invoice-form'
