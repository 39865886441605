import { InvoiceDiscountConfig } from '@npco/mp-gql-types'
import currency from 'currency.js'
import {
  INVOICE_ITEM_MIN_AMOUNT,
  INVOICE_TAX_EXCLUSIVE_RATE,
  INVOICE_TAX_INCLUSIVE_RATE,
} from 'features/Invoicing/components/Invoices/Invoice/Invoice.constants'
import {
  InvoiceDiscountFormFields,
  InvoiceFormFields,
  InvoiceItem,
} from 'features/Invoicing/components/Invoices/Invoice/Invoice.types'

import { convertLocaleStringToNumber } from 'utils/localeString'

import { getTaxRate } from '../InvoiceItemsAccordion.utils'

export interface ItemTotal {
  isDiscountInvalid: boolean
  isTotalBelowMinAmount: boolean
  itemDiscount: currency
  itemDiscountFormatted: string
  itemSubTotal: currency
  itemSubTotalFormatted: string
  itemTax: currency
  itemTaxFormatted: string
  itemTotal: currency
  itemTotalFormatted: string
}

export interface InvoiceTotals {
  discount: currency
  discountFormatted: string
  isDiscountInvalid: boolean
  isTotalAboveMaxAmount: boolean
  isTotalBelowMinAmount: boolean
  subTotal: currency
  subTotalFormatted: string
  taxes: currency
  taxesFormatted: string
  total: currency
  totalFormatted: string
}

export const getDiscount = ({
  discountPercentage,
  discountPrice,
  discountConfig,
  total,
}: {
  discountPercentage: string
  discountPrice: string
  discountConfig: InvoiceDiscountConfig
  total: currency
}) => {
  const percentage = convertLocaleStringToNumber(discountPercentage)
  const priceTotal = currency(discountPrice, { precision: 4 })

  const percentageTotal = percentage
    ? total.multiply(percentage).divide(100)
    : currency(0, { precision: 4 })

  const discount =
    discountConfig === InvoiceDiscountConfig.PERCENTAGE
      ? percentageTotal
      : priceTotal

  return discount
}

export const getItemTotals = ({
  discountValues,
  invoiceDiscount,
  invoiceSubTotal,
  item,
  itemsTaxInclusive,
}: {
  discountValues?: InvoiceDiscountFormFields
  invoiceDiscount: currency
  invoiceSubTotal: currency
  item: any
  itemsTaxInclusive: boolean
}) => {
  const rate = getTaxRate(item.taxApplicable, itemsTaxInclusive)

  const itemTotal = item.price
    .multiply(rate)
    .multiply(convertLocaleStringToNumber(item.quantity))

  const discount = discountValues || item.discount

  const itemDiscount = getDiscount({
    discountConfig: discount.config,
    discountPrice: discount.price,
    discountPercentage: discount.percentage,
    total: itemTotal,
  })

  const itemSubTotal = itemTotal.subtract(itemDiscount)

  const isTaxApplicable = Boolean(item.taxApplicable)

  const isInvoiceNegativeOrZero =
    invoiceSubTotal.subtract(invoiceDiscount).intValue <= 0

  const isItemNegativeOrZero = itemTotal.subtract(itemDiscount).intValue <= 0

  // NOTE: if we have zero value, negative item value or items sub total or
  // item is not tax applicable return

  // NOTE: rules for skipping tax calculation
  // * item is not tax applicable
  // * item price is the same as the discount
  // * item price is negative value with discount
  // * all items sub total price is negative value
  // * all items total price is $0.00
  // * invoice discount is zero
  const subTotalTaxRate = itemsTaxInclusive
    ? INVOICE_TAX_INCLUSIVE_RATE
    : INVOICE_TAX_EXCLUSIVE_RATE

  // IMPORTANT: we must use the raw values from this division result as
  // otherwise the currency instance will round the value to its precision set
  // post calculation e.g 0.3158 versus 0.3157894736842105
  // JIRA: https://npco-dev.atlassian.net/browse/ZD-14470
  const totalsQuotient = itemSubTotal.intValue / invoiceSubTotal.intValue

  const tax = currency(
    (itemSubTotal.intValue -
      Math.round(invoiceDiscount.intValue * totalsQuotient)) /
      subTotalTaxRate,
    { fromCents: true, precision: 4 }
  )

  const itemTax =
    isTaxApplicable && !isInvoiceNegativeOrZero && !isItemNegativeOrZero
      ? // NOTE: wrapping tax so fromCents is not passed down instance
        currency(tax, { precision: 4 })
      : currency(0, { precision: 4 })

  const isDiscountInvalid =
    itemTotal.subtract(itemDiscount).intValue < INVOICE_ITEM_MIN_AMOUNT

  const isTotalBelowMinAmount = itemTotal.intValue < INVOICE_ITEM_MIN_AMOUNT

  return {
    isDiscountInvalid,
    isTotalBelowMinAmount,
    itemDiscount,
    itemDiscountFormatted: currency(itemDiscount, { pattern: '-!#' }).format(),
    itemSubTotal,
    itemSubTotalFormatted: currency(itemSubTotal).format(),
    itemTax,
    itemTaxFormatted: currency(itemTax).format(),
    itemTotal,
    itemTotalFormatted: currency(itemTotal).format({ negativePattern: '-!#' }),
  }
}

export const getSubTotal = ({
  items,
  itemsTaxInclusive,
}: {
  items: InvoiceFormFields['items']
  itemsTaxInclusive: InvoiceFormFields['itemsTaxInclusive']
}) => {
  const invoiceSubTotal = items.reduce(
    (memo: currency, item: InvoiceItem): currency => {
      const rate = getTaxRate(item.taxApplicable, itemsTaxInclusive)

      const itemTotal = item.price
        .multiply(rate)
        .multiply(convertLocaleStringToNumber(item.quantity))

      const discount = getDiscount({
        discountConfig: item.discount.config,
        discountPrice: item.discount.price,
        discountPercentage: item.discount.percentage,
        total: itemTotal,
      })

      return memo.add(itemTotal).subtract(discount)
    },
    currency(0, { precision: 4 })
  )

  return invoiceSubTotal
}

export const getTaxes = ({
  invoiceDiscount,
  invoiceSubTotal,
  items,
  itemsTaxInclusive,
}: {
  invoiceDiscount: currency
  invoiceSubTotal: currency
  items: InvoiceFormFields['items']
  itemsTaxInclusive: InvoiceFormFields['itemsTaxInclusive']
}) => {
  const taxes = items.reduce((memo, item) => {
    const itemTotals = getItemTotals({
      invoiceDiscount,
      invoiceSubTotal,
      item,
      itemsTaxInclusive,
    })

    return memo.add(itemTotals.itemTax)
  }, currency(0, { precision: 4 }))

  return taxes
}

export const getInvoiceTotals = ({
  discountValues,
  values,
}: {
  discountValues?: InvoiceDiscountFormFields
  values: InvoiceFormFields
}) => {
  const { items, itemsTaxInclusive, maximumLimit, minimumLimit } = values

  const invoiceSubTotal = getSubTotal({ items, itemsTaxInclusive })

  const invoiceDiscountValues = discountValues || values.discount

  const invoiceDiscount = getDiscount({
    discountPrice: invoiceDiscountValues.price,
    discountPercentage: invoiceDiscountValues.percentage,
    discountConfig: invoiceDiscountValues.config,
    total: invoiceSubTotal,
  })

  const isDiscountInvalid =
    invoiceSubTotal.subtract(invoiceDiscount).intValue < 0

  const invoiceTaxes = getTaxes({
    invoiceDiscount,
    invoiceSubTotal,
    items,
    itemsTaxInclusive,
  })

  const invoiceTotal = itemsTaxInclusive
    ? invoiceSubTotal.subtract(invoiceDiscount)
    : invoiceSubTotal.add(invoiceTaxes).subtract(invoiceDiscount)

  // NOTE: maximum and minimum limits are in cents so convert to centi cents for
  // comparison
  const isTotalAboveMaxAmount =
    invoiceTotal.intValue > currency(maximumLimit, { precision: 4 }).intValue

  const isTotalBelowMinAmount =
    invoiceTotal.intValue < currency(minimumLimit, { precision: 4 }).intValue

  // NOTE: all currency.js instances returned have a precision value of 4 (centi
  // cents), formatted values are returned with a precision value of 2 (nearest
  // cent)
  const discount = currency(invoiceDiscount)
  const subTotal = currency(invoiceSubTotal)
  const taxes = currency(invoiceTaxes)
  const total = currency(invoiceTotal)

  return {
    discount,
    discountFormatted: discount.format({ pattern: '-!#' }),
    isDiscountInvalid,
    isTotalAboveMaxAmount,
    isTotalBelowMinAmount,
    subTotal,
    subTotalFormatted: subTotal.format(),
    taxes,
    taxesFormatted: taxes.format(),
    total,
    totalFormatted: total.format({ negativePattern: '-!#' }),
  }
}

// NOTE: All calculations are defined from the formulas within the following
// spreadsheet
// https://docs.google.com/spreadsheets/d/1sKBFdTcyXrHActj27pDDUipcjG8iDY6Yzl-h-5MdyKg/edit?usp=sharing
export const useInvoiceItemsCalculations = ({
  discountValues,
  values,
}: {
  values: InvoiceFormFields
  discountValues?: InvoiceDiscountFormFields
}) => {
  const totals = getInvoiceTotals({ discountValues, values })

  return {
    ...totals,
  }
}
