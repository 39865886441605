import { TypedEventTarget } from 'typescript-event-target'

import {
  type CatalogueItemFormFields,
  ITEM_ADD_NEW_ITEM_FIELD,
  ITEM_ATTRIBUTE_SETS_FIELD,
  ITEM_AVAILABILITY_FIELD,
  ITEM_CATEGORIES_FIELD,
  ITEM_DESCRIPTION_FIELD,
  ITEM_DISPLAY_LABEL_FIELD,
  ITEM_GTIN_FIELD,
  ITEM_IMAGES_FIELD,
  ITEM_INVOICES_ENABLED_FIELD,
  ITEM_IS_LABEL_INTERSECTING_FIELD,
  ITEM_IS_TAX_INCLUSIVE,
  ITEM_LABEL_FIELD,
  ITEM_MODIFIER_SETS_FIELD,
  ITEM_MODIFIER_SETS_SELECT_FIELD,
  ITEM_NAME_FIELD,
  ITEM_PRICE_FIELD,
  ITEM_REPORTING_CATEGORY_FIELD,
  ITEM_SITES_FIELD,
  ITEM_SKU_FIELD,
  ITEM_TAX_APPLICABLE_FIELD,
  ITEM_VARIANTS_FIELD,
  ItemTaxApplicableItems,
} from './CatalogueItem.types'

export const ITEM_CATEGORY_MIN = 1
export const ITEM_DEFAULT_PRICE = '0.00'
export const ITEM_DESCRIPTION_MAX_CHARACTERS = 1000
export const ITEM_MAX_AMOUNT = 50000
export const ITEM_MAX_REPORTING_CATEGORIES = 1
export const ITEM_MIN_AMOUNT = 0
export const ITEM_NAME_MAX_CHARACTERS = 256
export const ITEM_SKU_MAX_CHARACTERS = 32
export const ITEM_GTIN_MIN_CHARACTERS = 8
export const ITEM_GTIN_MAX_CHARACTERS = 14
export const ITEM_TAX_RATE = 10
export const ITEM_LABEL_MAX_CHARACTERS = 6

export const DEFAULT_ATTRIBUTE_SETS_GET_LIMIT = 50
export const DEFAULT_VARIANTS_LIMIT = 99
export const DEFAULT_ATTRIBUTE_SETS_LIMIT = 3
export const DEFAULT_MODIFIER_SETS_GET_LIMIT = 50
export const DEFAULT_MODIFIER_SETS_LIMIT = 3

export const ITEM_NAME_DUPLICATE_ERROR = '[400] Item exists with same name'

export const defaultValues: CatalogueItemFormFields = {
  [ITEM_ADD_NEW_ITEM_FIELD]: false,
  [ITEM_AVAILABILITY_FIELD]: true,
  [ITEM_CATEGORIES_FIELD]: [],
  [ITEM_DESCRIPTION_FIELD]: '',
  [ITEM_DISPLAY_LABEL_FIELD]: true,
  [ITEM_GTIN_FIELD]: '',
  [ITEM_INVOICES_ENABLED_FIELD]: true,
  [ITEM_IS_LABEL_INTERSECTING_FIELD]: false,
  [ITEM_IS_TAX_INCLUSIVE]: false,
  [ITEM_LABEL_FIELD]: '',
  [ITEM_NAME_FIELD]: '',
  [ITEM_IMAGES_FIELD]: [],
  [ITEM_PRICE_FIELD]: ITEM_DEFAULT_PRICE,
  [ITEM_REPORTING_CATEGORY_FIELD]: [],
  [ITEM_SKU_FIELD]: undefined,
  [ITEM_TAX_APPLICABLE_FIELD]: ItemTaxApplicableItems.TaxApplicable,
  [ITEM_ATTRIBUTE_SETS_FIELD]: [],
  [ITEM_MODIFIER_SETS_FIELD]: [],
  [ITEM_MODIFIER_SETS_SELECT_FIELD]: [],
  [ITEM_VARIANTS_FIELD]: [],
  [ITEM_SITES_FIELD]: undefined,
}

export type ItemAccordionEventMap = {
  shouldDisableReorder: CustomEvent<boolean>
  attributeSetsExpandedStatus: CustomEvent<{
    attributeSetId: string
    isExpanded: boolean
  }>
}

export const eventTarget = new TypedEventTarget<ItemAccordionEventMap>()
