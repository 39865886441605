import { SvgIcon } from '@npco/zeller-design-system'

import { ReactComponent as SimpleLoaderIcon } from 'assets/svg/simple-loader.svg'

import { StyledLoaderWrapper } from './LoaderSimple.styled'

export type LoaderSimpleProps = {
  size?: string
  isVisible?: boolean
} & Omit<JSX.IntrinsicElements['div'], 'ref'>

export const LoaderSimple = ({
  size = '16',
  isVisible = true,
  ...forwardedProps
}: LoaderSimpleProps) => {
  return (
    <StyledLoaderWrapper
      isVisible={isVisible}
      data-testid="loader-simple"
      $size={size}
      {...forwardedProps}
    >
      <SvgIcon width={size} height={size}>
        <SimpleLoaderIcon />
      </SvgIcon>
    </StyledLoaderWrapper>
  )
}
