import styled, { keyframes } from 'styled-components'

const animation = keyframes`
  from {
    transform: rotateZ(0);
  }

  to {
    transform: rotateZ(360deg);
  }
`

export const StyledLoaderWrapper = styled.div<{
  isVisible: boolean
  $size: string
}>`
  display: inline-block;
  animation: ${animation} 1s linear infinite;
  opacity: 0;
  transition: all 0.5s cubic-bezier(0.19, 0.73, 0.19, 0.98);
  height: ${({ $size }) => $size}px;
  width: ${({ $size }) => $size}px;

  ${({ isVisible }) =>
    isVisible &&
    `
      opacity: 1;
    `};
`
