// Exporting type for ease of use throughout the app
// Disabling the eslint rule as the non-camelcase variable is not defined in the context
// and it seems as though that is a bug in eslint
// eslint-disable-next-line camelcase
import { VerifyResult } from '@npco/mp-gql-types'
import type {
  rvSearchPhrase,
  rvSelectedDates,
  rvSelectedRates,
  rvSelectedSources,
  rvSelectedStatuses,
  rvSelectedTerminals,
  rvSelectedTypes,
  rvToggleFilters,
} from 'apps/component-merchant-portal/src/graphql/reactiveVariables'
import { rvSelectedTags } from 'features/Contacts/rv-deprecated/contacts'

import {
  SearchEntityDetailsFull_searchEntityDetailsFull_businessAddress as BusinessAddress,
  SearchEntityDetailsFull_searchEntityDetailsFull_registeredAddress as RegisteredAddress,
} from 'types/gql-types/SearchEntityDetailsFull'

export interface EmailProps {
  email: string
}

export interface PasswordProps {
  password: string
}

export type FieldValuesProps = EmailProps & PasswordProps

export interface SetFieldProps {
  setFieldError: (field: string, message: string) => void
  setSubmitting: (isSubmitting: boolean) => void
}

export interface DetailsProps {
  registeredAddress: RegisteredAddress
  businessAddress: BusinessAddress
}

export enum BUTTON_SIZE {
  LARGE = 'LARGE',
  DEFAULT = 'DEFAULT',
  SMALL = 'SMALL',
  XSMALL = 'XSMALL',
}

export enum AVATAR_BADGE_SIZE {
  XLARGE = 'XLARGE',
  LARGE = 'LARGE',
  DEFAULT = 'DEFAULT',
  SMALL = 'SMALL',
  XSMALL = 'XSMALL',
  XXSMALL = 'XXSMALL',
}

export enum INPUT_SIZE {
  LARGE = 'LARGE',
  DEFAULT = 'DEFAULT',
  SMALL = 'SMALL',
}

export type CustomValidator<T, U = undefined> = (
  value: T,
  secondValue?: U
) => string | undefined

export interface VerifyCustomerDocumentsResults {
  customerUuid: string
  resultDriversLicence: VerifyResult
  resultPassport: VerifyResult
  resultMedicareCard: VerifyResult
  result: VerifyResult
  error: string
}

export interface FiltersDefaultValues {
  selectedRates: Parameters<typeof rvSelectedRates>[0]
  selectedDates: Parameters<typeof rvSelectedDates>[0]
  selectedSources: Parameters<typeof rvSelectedSources>[0]
  selectedStatuses: Parameters<typeof rvSelectedStatuses>[0]
  selectedTerminals: Parameters<typeof rvSelectedTerminals>[0]
  searchPhrase: Parameters<typeof rvSearchPhrase>[0]
  selectedTypes: Parameters<typeof rvSelectedTypes>[0]
  toggleFilters: Parameters<typeof rvToggleFilters>[0]
  selectedTags: Parameters<typeof rvSelectedTags>[0]
}
