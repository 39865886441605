import { useCallback, useEffect } from 'react'

import { LAST_ACTIVITY_TIME } from '../../localStorage/keys'

type UseCheckInactivityTimeProps = {
  inactivityTimeout: number
  countdownTime: number
  handleActivity: () => void
  goToLogoutWithInactivityTimeout: () => void
}

export const useCheckInactivityTime = ({
  inactivityTimeout,
  countdownTime,
  handleActivity,
  goToLogoutWithInactivityTimeout,
}: UseCheckInactivityTimeProps) => {
  const handleInactivityCheck = useCallback(() => {
    if (!inactivityTimeout || !countdownTime) {
      return
    }
    const lastActivity = localStorage.getItem(LAST_ACTIVITY_TIME)
    if (!lastActivity) {
      handleActivity()
      return
    }
    const totalActiveTime = inactivityTimeout + countdownTime
    const lastActivityTime = parseInt(lastActivity, 10)
    const currentTime = Date.now()
    const timeSinceLastActivity = currentTime - lastActivityTime

    if (timeSinceLastActivity < totalActiveTime * 1000) {
      handleActivity()
      return
    }
    goToLogoutWithInactivityTimeout()
  }, [
    countdownTime,
    goToLogoutWithInactivityTimeout,
    inactivityTimeout,
    handleActivity,
  ])

  const handleVisibilityChange = useCallback(() => {
    if (document.visibilityState === 'visible') {
      handleInactivityCheck()
    }
  }, [handleInactivityCheck])

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [countdownTime, handleVisibilityChange, inactivityTimeout])
}
