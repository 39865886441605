import { BodySmallTypography, COLOR } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const CheckIndicator = styled.div`
  position: relative;
  display: inline-table;
  min-width: 1.25rem;
  min-height: 1.25rem;
  border-radius: 4px;
  margin-right: 0.875rem;
  border: 1px solid ${({ theme }) => theme.colors.GREY_250};
  background-color: ${({ theme }) => theme.colors.WHITE};
  transition: background-color 0.3s ease-out, border-color 0.3s ease-out;
  box-sizing: border-box;

  svg {
    position: absolute;
    top: 4px;
    left: 4px;
  }

  &.checked {
    border-color: ${({ theme }) => theme.colors.BLUE_1000};
    background-color: ${({ theme }) => theme.colors.BLUE_1000};
  }

  &.disabled {
    opacity: 0.5;
  }
`

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-width: 0;
`

export const Label = styled.div`
  transition: color 0.3s ease-out;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const Sublabel = styled.div`
  color: ${({ theme }) => theme.colors.GREY_400};
  ${BodySmallTypography};
  transition: color 0.3s ease-out;
`

export const Item = styled.li<{ $checked?: boolean }>`
  display: flex;
  align-items: center;
  list-style: none;
  padding: 0.875rem 1rem;
  cursor: pointer;
  border-radius: 7px;

  color: ${({ theme }) => theme.colors.BLACK};
  &[data-disabled='true'] {
    pointer-events: none;
    cursor: not-allowed;
    color: ${({ theme }) => theme.colors.GREY_400};
  }

  ${({ $checked }) =>
    $checked ? `background-color: ${COLOR.BLUE_80}};` : undefined}

  &.isCompact {
    border-bottom: 1px solid ${({ theme }) => theme.colors.GREY_150};

    &:last-of-type {
      border-bottom: none;
    }
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.GREY_30};
  }

  &:focus-visible {
    outline: ${({ theme }) => theme.colors.BLUE_1000} solid 1px;
  }
`
