import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'
import { TabItemType } from '@npco/zeller-design-system'
import { v4 } from 'uuid'

/**
 * Following hardcoded data will be removed in the final implementation
 */
export const dummyTabs: TabItemType[] = new Array(10)
  .fill(true)
  .map((_, index) => ({
    id: `tab-${index + 1}`,
    title: `Tab ${index + 1}`,
  }))

export const useMultiEntityTabs = () => {
  const [entities, setEntities] = useState<TabItemType[]>(dummyTabs)

  const [activeEntityId, setActiveEntityId] = useState<TabItemType['id']>(
    entities[0]?.id
  )

  const navigate = useNavigate()

  const setSelectedEntityId = (entityId: TabItemType['id']) => {
    // handle setting active tab here
    /** This will be removed in the final implementation */
    sessionStorage.setItem('activeEntityId', JSON.stringify(entityId))
    navigate('/')
  }
  const reorderEntities = (updatedEntities: TabItemType[]) => {
    // handle tab updates here, such as ordering update etc
    setEntities(updatedEntities)
  }
  const closeEntitySession = (entityId: TabItemType['id']) => {
    // handle closing of tabs
    const updatedEntities = entities.filter((entity) => entity.id !== entityId)
    setEntities(updatedEntities)
  }

  const addNewEntitySession = () => {
    // redirect to add new business here
    const newId = v4()
    setEntities([
      ...entities,
      { id: `tab-${newId}`, title: `Tab ${entities.length + newId}` },
    ])
  }

  /**
   * This useEffect will be removed in the final implementation
   */
  useEffect(() => {
    /** will be removed in the final implementation */
    const sessionEntityId = sessionStorage.getItem('activeEntityId')
    if (sessionEntityId) {
      setActiveEntityId(JSON.parse(sessionEntityId))
    }
  }, [])

  return {
    entities,
    activeEntityId,
    setSelectedEntityId,
    reorderEntities,
    closeEntitySession,
    addNewEntitySession,
  }
}
