import { useEffect, useMemo, useState } from 'react'
import { NetworkStatus, useLazyQuery } from '@apollo/client'
import { TransactionFilterInput } from '@npco/mp-gql-types'
import { GetTransactions } from 'apps/component-merchant-portal/src/graphql/merchant-portal/queries/transactions'
import { rvFinishedLoading } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'
import { rvDeferredFilterPayload } from 'apps/component-merchant-portal/src/graphql/reactiveVariables/filters'
import { debounce } from 'debounce'
import { rvIsContactPaymentFiltersDisabled } from 'features/Contacts/rv-deprecated/contacts'
import { Observable, Subscription } from 'rxjs'
import { map, tap } from 'rxjs/operators'

import { resetFilters } from 'utils/common'
import { FiltersDefaultValues } from 'types/common'
import {
  GetTransactions as GetTransactionsType,
  GetTransactionsVariables,
} from 'types/gql-types/GetTransactions'
import { TIME_FILTER_ENABLED_COMPONENTS } from 'types/picker'
import { TransactionInList } from 'types/transactions'
import { AnalyticsEventNames } from 'services/Analytics/events'
import { TransactionsFilteredPayload } from 'services/Analytics/events/transaction'
import { useAnalyticsContext } from 'services/Analytics/useAnalyticsContext'
import { useAnalyticsLogger } from 'services/Analytics/useAnalyticsLogger'
import { useTransactionUpdates } from 'services/transactionUpdates/hooks/useTransactionUpdates'

import {
  groupTransactions,
  groupTransactionsByYear,
  setLastSelectedDates,
} from './useTransactionsList.utils'
import { useTransactionsWithUpdates } from './useTransactionsWithUpdates'

export const TRANSACTIONS_LIMIT = 40
const FILTER_CHANGE_EVENT_DEBOUNCE = 10000

interface UseTransactionsListProps {
  filterObject: TransactionFilterInput | undefined
  areFiltersSelected: boolean
  filterDefaultValues?: Partial<FiltersDefaultValues>
  widgetKey?: TIME_FILTER_ENABLED_COMPONENTS
  limit?: number
}

export const useTransactionsList = ({
  filterObject,
  areFiltersSelected,
  filterDefaultValues,
  widgetKey,
  limit = TRANSACTIONS_LIMIT,
}: UseTransactionsListProps) => {
  const [transactionList, setTransactionList] = useState<
    TransactionInList[][] | undefined
  >(undefined)
  const { transactionUpdates$ } = useTransactionUpdates()
  const { locationName } = useAnalyticsContext()
  const { trackAnalyticsEvent } = useAnalyticsLogger()

  const debounceAnalyticsEvent = useMemo(
    () =>
      debounce((payload: TransactionsFilteredPayload) => {
        if (Object.keys(payload).length > 1) {
          trackAnalyticsEvent(
            AnalyticsEventNames.TRANSACTIONS_FILTERED,
            payload
          )
          rvDeferredFilterPayload({})
        }
      }, FILTER_CHANGE_EVENT_DEBOUNCE),
    [trackAnalyticsEvent]
  )

  const { setTransactions, transactions$, updateObserver } =
    useTransactionsWithUpdates()

  useEffect(() => {
    if (!areFiltersSelected) {
      const updatesSubscription = transactionUpdates$?.subscribe(updateObserver)
      return () => updatesSubscription?.unsubscribe()
    }

    return () => {
      debounceAnalyticsEvent.flush()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areFiltersSelected])

  const contactId = filterObject?.contactUuid?.in[0]

  const groupedTransactionList$: Observable<TransactionInList[][]> = contactId
    ? transactions$.pipe(
        map(groupTransactionsByYear),
        tap(() => {
          rvFinishedLoading(true)
        })
      )
    : transactions$.pipe(
        map(groupTransactions),
        tap(() => {
          rvFinishedLoading(true)
        })
      )

  const sentTransactionsFilteredEvent = () => {
    debounceAnalyticsEvent.clear()
    debounceAnalyticsEvent({
      ...rvDeferredFilterPayload(),
      Location: locationName.current,
    })
  }

  const [
    fetchTransactionsData,
    {
      called,
      data,
      fetchMore,
      error,
      refetch,
      networkStatus,
      loading: isQueryLoading,
    },
  ] = useLazyQuery<GetTransactionsType, GetTransactionsVariables>(
    GetTransactions,
    {
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first',
      errorPolicy: 'all',
      variables: {
        limit,
        filter: filterObject,
      },
      notifyOnNetworkStatusChange: true,
      onCompleted: (res) => {
        setTransactions(res?.getTransactions.transactions || [])
        sentTransactionsFilteredEvent()
        setLastSelectedDates(widgetKey)
      },
    }
  )

  // Use to handle partial data becuase we can't access data in onError callback in v3.11.1 anymore
  useEffect(() => {
    if (error) {
      setTransactions(data?.getTransactions.transactions || [])
      sentTransactionsFilteredEvent()
      setLastSelectedDates(widgetKey)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error])

  const isLoading =
    isQueryLoading ||
    !called ||
    (transactionList === undefined && error === undefined)

  useEffect(() => {
    rvIsContactPaymentFiltersDisabled(isLoading)
  }, [isLoading])

  useEffect(() => {
    rvFinishedLoading(false)
    resetFilters(filterDefaultValues)
    rvDeferredFilterPayload({ Location: locationName.current })
    fetchTransactionsData()

    return () => {
      setLastSelectedDates(widgetKey)
      resetFilters()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchTransactionsData, widgetKey])

  const onLoadMore = async () => {
    const nextToken = data?.getTransactions?.nextToken

    if (!nextToken?.type) {
      return
    }

    fetchMore?.({
      variables: {
        limit,
        nextToken,
        filter: filterObject,
      },
    })
  }

  const hasMore = Boolean(data?.getTransactions?.nextToken)

  const subscription = new Subscription()

  useEffect(() => {
    subscription.add(
      groupedTransactionList$.subscribe((res) => {
        setTransactionList(res)
      })
    )

    return () => subscription.unsubscribe()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    groupedTransactionList$,
    transactionList: transactionList ?? [],
    onLoadMore,
    isLoading,
    isLoadingMore: networkStatus === NetworkStatus.fetchMore,
    refetching: networkStatus === NetworkStatus.refetch,
    hasMore,
    transactionsError: error,
    refetch,
  }
}
