import { LoginEntityType } from '@npco/mp-utils-selected-entity'

interface AddressOptions {
  street: string | null
  suburb: string | null
  state: string | null
  postcode: string | null
}

export const joinStreetWithSuburbStateAndPostcode = ({
  street,
  suburb,
  state,
  postcode,
}: AddressOptions) => {
  const suburbStateAndPostcode = [suburb, state, postcode]
    .filter(Boolean)
    .join(' ')

  return [street, suburbStateAndPostcode].filter(Boolean).join(', ')
}

export const combineStreets = (
  street1: string | null,
  street2: string | null
) => [street2, street1].filter(Boolean).join(' ')

export const formatEntityAddress = (
  merchantAddress: Omit<
    NonNullable<LoginEntityType['registeredAddress']>,
    '__typename'
  >
) => {
  const streetAddress = combineStreets(
    merchantAddress.street1,
    merchantAddress.street2
  )

  return joinStreetWithSuburbStateAndPostcode({
    street: streetAddress,
    ...merchantAddress,
  })
}

export const getUnitWithStreetNumber = (
  streetNumber: string,
  unit?: string
) => {
  if (!unit) {
    return streetNumber
  }

  return `${unit}/${streetNumber}`
}
