import { useEffect } from 'react'
import { useLocation } from 'react-router-dom-v5-compat'
import { BREAKPOINT, zIndexMap } from '@npco/zeller-design-system'
import { AnimatePresence, motion, Variant } from 'framer-motion'

import { useImagePreloader } from 'hooks/useImagePreloader/useImagePreloader'
import { useIsMobileResolution } from 'hooks/useIsMobileResolution'
import { ROOT } from 'const/routes'
import { FadeUpAnimation } from 'components/FadeUpAnimation/FadeUpAnimation'

import { DEFAULT_OVERLAY } from '../common/components/SpotlightModalOverlay/SpotlightModalOverlay'
import { useSetupFlowContext } from './contexts/SetupFlowContext'
import { useGoToSetupFlowStage } from './hooks/useSetupFlowStage/useGoToSetupFlowStage'
import { getPreLoadImages } from './SetupFlow.utils'
import { useSetupFlowModal } from './SetupFlowModal/hooks/useSetupFlowModal'
import { useSetupFlowModalState } from './SetupFlowModal/hooks/useSetupFlowModalState'
import { SetupFlowModal } from './SetupFlowModal/SetupFlowModal'

const variants: Record<string, Variant> = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
}

export const SetupFlow = () => {
  const { goToSetupFlowStage } = useGoToSetupFlowStage()
  const { isSpotlightVisible, stage } = useSetupFlowModalState()
  const isMobile = useIsMobileResolution()
  const isMobileOrTablet = useIsMobileResolution(BREAKPOINT.MD)
  const { isLoaded, exitClicked } = useSetupFlowContext()
  const { completeFlow } = useSetupFlowModal()

  const { imagesPreloaded } = useImagePreloader(
    getPreLoadImages({ isMobile, isTablet: isMobileOrTablet && !isMobile })
  )
  const location = useLocation()
  const onPath = location.pathname === ROOT.PORTAL.OVERVIEW.PAYMENTS.path

  useEffect(() => {
    if (onPath && imagesPreloaded && isLoaded) {
      goToSetupFlowStage({
        stage: stage || 'SetupFlowStartStage',
      })
    }
    // won't watch location state changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goToSetupFlowStage, location.pathname, imagesPreloaded, isLoaded])

  return (
    // NOTE: These props are necessary so framer will finish the exit animation before
    // starting the next animation and will not animate position changes
    <AnimatePresence exitBeforeEnter presenceAffectsLayout>
      {!exitClicked && isLoaded && onPath && (
        <motion.div
          key="setup-flow-motion"
          variants={variants}
          initial="initial"
          animate="animate"
          exit="exit"
          style={
            isSpotlightVisible
              ? {
                  zIndex: zIndexMap.modalOverlay,
                  position: 'absolute',
                  inset: 0,
                }
              : DEFAULT_OVERLAY
          }
          transition={{
            duration: 0.5,
          }}
          onAnimationComplete={(variant) => {
            if (variant === 'exit') completeFlow()
          }}
        >
          <FadeUpAnimation>
            <div id="setup-flow-modal-root">
              <SetupFlowModal />
            </div>
          </FadeUpAnimation>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
