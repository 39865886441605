import { useMemo } from 'react'
import { rvEntityDetails } from '@npco/mp-utils-selected-entity'
import { Flex, PageTemplate } from '@npco/zeller-design-system'

import { Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs'
import { UnexpectedErrorMessage } from 'components/UnexpectedErrorMessage/UnexpectedErrorMessage'

import { AllSitesFeesAndPricingTableList } from './AllSitesFeesAndPricingTableList/AllSitesFeesAndPricingTableList'
import { CardPayments } from './CardPayments/CardPayments'
import { CommonFeesAndPricing } from './CommonFeesAndPricing'
import { useSitesWithFeesAndPricing } from './FeesAndPricing.hook'
import { getSitesWithUniquePayments } from './FeesAndPricing.utils'
import { FeesAndPricingLoadingSkeleton } from './FeesAndPricingLoadingSkeleton/FeesAndPricingLoadingSkeleton'
import { InvoiceFeesAndPricingContainer } from './InvoiceFeesAndPricing/InvoiceFeesAndPricingContainer'
import { useXeroSurchargeSettings } from './InvoiceFeesAndPricing/useXeroSurchargeSettings'
import { useZellerSurchargeSettings } from './InvoiceFeesAndPricing/useZellerSurchargeSettings'
import { PaymentTable } from './PaymentTable/PaymentTable'

export const FeesAndPricing = () => {
  const { allSites, isLoading, error } = useSitesWithFeesAndPricing()
  const { zellerSurchargeSettings, isLoadingZellerSettings } =
    useZellerSurchargeSettings()
  const { xeroSurchargeSettings, isLoadingXeroSettings } =
    useXeroSurchargeSettings()

  const content = useMemo(() => {
    if (error) {
      return <UnexpectedErrorMessage />
    }

    if (isLoading || isLoadingZellerSettings || isLoadingXeroSettings) {
      return <FeesAndPricingLoadingSkeleton />
    }
    const uniquePayments = getSitesWithUniquePayments(allSites)
    const entityFee = rvEntityDetails().feeRateSettings ?? undefined

    if (uniquePayments.length === 0) {
      return (
        <>
          <CardPayments />
          <PaymentTable
            cardPresentPayments={entityFee?.feePercent}
            motoPayments={entityFee?.feePercentMoto}
          />
          <CommonFeesAndPricing entityFee={entityFee} />
          <InvoiceFeesAndPricingContainer
            xeroFees={xeroSurchargeSettings ?? undefined}
            zellerFees={zellerSurchargeSettings ?? undefined}
          />
        </>
      )
    }

    if (uniquePayments.length === 1) {
      return (
        <>
          <CardPayments />
          <PaymentTable
            cardPresentPayments={uniquePayments[0].surchargesTaxes?.feePercent}
            motoPayments={uniquePayments[0].surchargesTaxes?.feePercentMoto}
          />
          <CommonFeesAndPricing entityFee={entityFee} />
          <InvoiceFeesAndPricingContainer
            xeroFees={xeroSurchargeSettings ?? undefined}
            zellerFees={zellerSurchargeSettings ?? undefined}
          />
        </>
      )
    }

    return (
      <>
        <CardPayments />
        <CommonFeesAndPricing entityFee={entityFee} />
        <AllSitesFeesAndPricingTableList
          allSites={allSites}
          xeroFees={xeroSurchargeSettings ?? undefined}
          zellerFees={zellerSurchargeSettings ?? undefined}
        />
      </>
    )
  }, [
    allSites,
    error,
    isLoading,
    isLoadingXeroSettings,
    isLoadingZellerSettings,
    xeroSurchargeSettings,
    zellerSurchargeSettings,
  ])

  return (
    <PageTemplate
      HeaderPrimaryRow={
        <PageTemplate.HeaderPrimaryRow>
          <Flex height="48px" alignItems="center">
            <Breadcrumbs />
          </Flex>
        </PageTemplate.HeaderPrimaryRow>
      }
    >
      <PageTemplate.Section>{content}</PageTemplate.Section>
    </PageTemplate>
  )
}
