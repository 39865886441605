import { useCallback, useMemo } from 'react'
import { InvoiceItemUnit } from '@npco/mp-gql-types'
import { Flex, Heading } from '@npco/zeller-design-system'
import currency from 'currency.js'
import { INVOICE_DEFAULT_UNIT_QUANTITY } from 'features/Invoicing/components/Invoices/Invoice/Invoice.constants'
import { InvoiceItemCalculateFormFields } from 'features/Invoicing/components/Invoices/Invoice/Invoice.types'
import { useFormikContext } from 'formik'
import { isEmpty, isEqual } from 'lodash-es'

import { convertLocaleStringToNumber } from 'utils/localeString'
import { translate } from 'utils/translations'

import { getTaxRate } from '../../../../InvoiceItemsAccordion.utils'
import {
  InvoiceTotalWrapper,
  ItemResetCalculate,
  ItemTotalValue,
} from './InvoiceItemCalculateTotals.styled'

export const translations = {
  resetCalculate: translate('page.invoice.formSections.items.resetCalculate'),
  total: translate('page.invoice.formSections.items.itemsCalculateTotal'),
}

export interface InvoiceItemsCalculateTotalsProps {
  isTaxApplicable: boolean
  isTaxInclusive: boolean
}

export const InvoiceItemsCalculateTotals = ({
  isTaxApplicable,
  isTaxInclusive,
}: InvoiceItemsCalculateTotalsProps) => {
  const { errors, setValues, values } =
    useFormikContext<InvoiceItemCalculateFormFields>()

  const hasModifiedCalculateFields = useMemo(() => {
    return !isEqual(
      {
        quantity: INVOICE_DEFAULT_UNIT_QUANTITY,
        unit: InvoiceItemUnit.QUANTITY,
      },
      {
        quantity: values.quantity,
        unit: values.unit,
      }
    )
  }, [values])

  const handleReset = useCallback(() => {
    setValues({
      ...values,
      quantity: INVOICE_DEFAULT_UNIT_QUANTITY,
      unit: InvoiceItemUnit.QUANTITY,
    })
  }, [setValues, values])

  const hasValidationError = !isEmpty(errors)

  const rate = getTaxRate(isTaxApplicable, isTaxInclusive)

  const total = hasValidationError
    ? 0
    : values.price
        .multiply(rate)
        .multiply(convertLocaleStringToNumber(values.quantity))

  const totalFormatted = currency(total).format()

  return (
    <Flex flexDirection="column">
      <InvoiceTotalWrapper>
        <ItemResetCalculate
          dataTestId="invoicing-invoice-line-item-calculate-reset"
          disabled={!hasModifiedCalculateFields}
          onClick={handleReset}
        >
          {translations.resetCalculate}
        </ItemResetCalculate>
        <Flex alignItems="flex-end" flexDirection="column" flex={1}>
          <Flex gridGap="8px">
            <Heading.H4>{`${translations.total}:`}</Heading.H4>
            <ItemTotalValue dataTestId="invoicing-invoice-line-item-calculate-total-value">
              {totalFormatted}
            </ItemTotalValue>
          </Flex>
        </Flex>
      </InvoiceTotalWrapper>
    </Flex>
  )
}
