import { useCallback, useState } from 'react'
import { useTranslations } from '@npco/utils-translations'
import {
  BREAKPOINT,
  BUTTON_SIZE,
  ButtonGhostIconLeft,
  Ellipsize,
  ErrorMessageForm,
  Flex,
  InputSelectComboboxStandard,
  InputSelectComboboxStandardItemWrapper,
  Message,
  PlusIcon,
  SelectInputBasicProps,
  Tabs,
} from '@npco/zeller-design-system'
import currency from 'currency.js'
import { Subject } from 'rxjs'

import { useCustomerItemPermissions } from 'hooks/useGetCustomerItemPermissions/useCustomerItemPermissions'
import { useIsMobileResolution } from 'hooks/useIsMobileResolution'
import { ROOT } from 'const/routes'

import {
  InvoiceNameSelectedItem,
  InvoiceNameSelectedTab,
} from '../../../../InvoiceFormAccordions.types'
import { getTaxRate } from '../../InvoiceItemsAccordion.utils'
import { useInvoiceNameCombobox } from './hooks/useInvoiceNameCombobox'
import { useInvoiceNameItems } from './hooks/useInvoiceNameItems'
import { translations } from './InvoiceItemName.i18n'
import { InvoiceNameItemPrice } from './InvoiceItemName.styled'

export interface InvoiceItemNameProps {
  index: number
  lineIndex: number
  name: string
}

export const InvoiceItemName = ({
  index,
  // lineIndex,
  name,
}: InvoiceItemNameProps) => {
  const [name$] = useState(new Subject<string>())
  const t = useTranslations(translations)

  const { allowItemManagement, loading } = useCustomerItemPermissions()

  const {
    error,
    handleBlur,
    handleChange,
    handleInputChange,
    handleInputClear,
    inputValue,
    isTaxInclusive,
    selectedItem,
    selectedTab,
    tabs,
  } = useInvoiceNameCombobox({ index, name$ })

  const {
    hasItems,
    hasMore,
    isLoading: isLoadingItems,
    items,
    loadMore,
  } = useInvoiceNameItems({
    name$,
    selectedItem,
    selectedTab,
  })

  const isLoading = loading || isLoadingItems

  const isMobile = useIsMobileResolution(BREAKPOINT.LG)

  const renderItem: SelectInputBasicProps<InvoiceNameSelectedItem>['renderItem'] =
    useCallback(
      (renderProps) => {
        const { label, price, taxApplicable } = renderProps.item

        // NOTE: price returned from item service is tax exclusive so must update
        // price if invoice is tax inclusive
        const calculatedPrice = price.multiply(
          getTaxRate(taxApplicable, isTaxInclusive)
        )
        const priceFormatted = currency(calculatedPrice).format()

        return (
          <InputSelectComboboxStandardItemWrapper {...renderProps}>
            <Flex
              flex={1}
              alignItems="center"
              justifyContent="space-between"
              maxWidth="100%"
            >
              <Ellipsize>{label}</Ellipsize>
              <InvoiceNameItemPrice>{priceFormatted}</InvoiceNameItemPrice>
            </Flex>
          </InputSelectComboboxStandardItemWrapper>
        )
      },
      [isTaxInclusive]
    )

  const navigateToCreateItem = useCallback(() => {
    window.open(ROOT.PORTAL.ITEMS.CREATE.path, '_blank')
    handleInputClear?.()
  }, [handleInputClear])

  const renderNoItemsPlaceholder = useCallback(() => {
    if (inputValue && !isLoading) {
      const icon = () => <PlusIcon />
      return (
        <Message
          description={
            allowItemManagement
              ? t('noResultsDescription')
              : t('NoResultsDescriptionNoPermission')
          }
          primaryButton={
            allowItemManagement ? (
              <ButtonGhostIconLeft
                size={BUTTON_SIZE.XSMALL}
                onClick={navigateToCreateItem}
                icon={icon}
              >
                {t('createItem')}
              </ButtonGhostIconLeft>
            ) : undefined
          }
          title={t('noResultsTitle')}
        />
      )
    }

    if (isLoading) {
      return null
    }

    if (hasItems) {
      return null
    }

    const isSavedItemsTab = selectedTab === InvoiceNameSelectedTab.SavedItems

    const title = isSavedItemsTab
      ? t('noSavedItemsTitle')
      : t('noRecentlyUsedItemsTitle')

    const description = isSavedItemsTab
      ? undefined
      : t('noRecentlyUsedItemsDescription')

    return <Message description={description} title={title} />
  }, [
    allowItemManagement,
    hasItems,
    inputValue,
    isLoading,
    navigateToCreateItem,
    selectedTab,
    t,
  ])

  const renderHeader = useCallback(() => {
    return (
      <Flex paddingLeft="24px" paddingRight="24px" marginBottom="10px">
        <Tabs currentTab={selectedTab} size="small">
          {tabs.map((tab) => (
            <Tabs.Item key={tab.value} {...tab} />
          ))}
        </Tabs>
      </Flex>
    )
  }, [tabs, selectedTab])

  return (
    <>
      <InputSelectComboboxStandard<InvoiceNameSelectedItem>
        // NOTE: add prop support to design system
        // aria-label={ariaLabel}
        // data-testid={`invoicing-invoice-line-item-name-${lineIndex}`}
        hasError={Boolean(error)}
        hasMore={hasMore}
        inputValue={inputValue}
        isLoading={isLoading}
        items={items}
        label={t('label')}
        name={name}
        onBlur={handleBlur}
        onChange={handleChange}
        onInputClear={handleInputClear}
        onInputChange={handleInputChange}
        onLoadMoreItems={loadMore}
        renderNoItemsPlaceholder={renderNoItemsPlaceholder}
        popperWidth={isMobile ? undefined : '680px'}
        renderHeader={renderHeader}
        renderItem={renderItem}
        selectedItem={selectedItem}
      />
      <ErrorMessageForm hasError={Boolean(error)} errorMessage={error} />
    </>
  )
}
