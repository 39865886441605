import { useMemo } from 'react'
import { rvEntityDetails } from '@npco/mp-utils-selected-entity'
import { TransactionUpdate } from 'apps/component-merchant-portal/src/graphql/merchant-portal/subscriptions/transactions'
import { Subject } from 'rxjs'

import { useSubscription } from 'hooks/useSubscription'
import {
  TransactionUpdate as TransactionUpdateResponse,
  TransactionUpdateVariables,
} from 'types/gql-types/TransactionUpdate'
import { TransactionUpdateData } from 'types/transactions'

export const useTransactionUpdateSubscription = () => {
  const transactionUpdates$ = useMemo(
    () => new Subject<TransactionUpdateData>(),
    []
  )
  const entityUuid = rvEntityDetails()?.id

  useSubscription<TransactionUpdateResponse, TransactionUpdateVariables>(
    TransactionUpdate,
    {
      onSubscriptionData: (res) => {
        if (res.subscriptionData.data?.onTransactionUpdate) {
          transactionUpdates$.next(
            res.subscriptionData.data?.onTransactionUpdate
          )
        }
      },
      skip: !entityUuid,
      variables: {
        entityUuid,
      },
    }
  )

  return { transactionUpdates$ }
}
