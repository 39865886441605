import { useTranslations } from '@npco/utils-translations'
import { Breadcrumb, PageTemplate } from '@npco/zeller-design-system'

import { manageBusinessTranslations } from './ManageBusiness.i18n'

export const ManageBusiness = () => {
  const t = useTranslations(manageBusinessTranslations)

  return (
    <PageTemplate
      HeaderPrimaryRow={
        <PageTemplate.HeaderPrimaryRow>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Breadcrumb.Text>{t('header')}</Breadcrumb.Text>
            </Breadcrumb.Item>
          </Breadcrumb>
        </PageTemplate.HeaderPrimaryRow>
      }
    >
      <div>Manage Business</div>
    </PageTemplate>
  )
}
