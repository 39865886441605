import { useCallback, useEffect, useRef, useState } from 'react'
import { throttle } from 'throttle-debounce'

import { LAST_ACTIVITY_TIME } from '../../localStorage/keys'

type useActivityTrackerProps = {
  inactivityTimeout: number
}
export const useActivityTimer = ({
  inactivityTimeout,
}: useActivityTrackerProps) => {
  const [isCountdownActive, setIsCountdownActive] = useState(false)
  const workerRef = useRef<Worker | null>(null)

  const handleWorkerMessage = useCallback((e: MessageEvent) => {
    const { type } = e.data
    if (type === 'timeout') {
      setIsCountdownActive(true)
    }
  }, [])

  useEffect(() => {
    workerRef.current = new Worker(
      new URL('../../../WebWorker/activityTimerWorker', import.meta.url),
      { type: 'module' }
    )

    workerRef.current.onmessage = (e) => handleWorkerMessage(e)

    return () => {
      if (workerRef.current) {
        workerRef.current.terminate()
      }
    }
  }, [handleWorkerMessage])

  const restartActivityTimer = useCallback(() => {
    workerRef.current?.postMessage({
      type: 'startTimer',
      payload: { inactivityTimeout },
    })
  }, [inactivityTimeout])

  const resetUserActivityState = useCallback(() => {
    if (!inactivityTimeout || isCountdownActive) {
      return
    }
    localStorage.setItem(LAST_ACTIVITY_TIME, Date.now().toString())
    restartActivityTimer()
  }, [inactivityTimeout, isCountdownActive, restartActivityTimer])

  const clearActivityTimer = useCallback(() => {
    workerRef.current?.postMessage({ type: 'clearTimer' })
  }, [])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleActivity = useCallback(
    throttle(1000, () => {
      resetUserActivityState()
    }),
    [resetUserActivityState]
  )

  const addEventListeners = useCallback(() => {
    document.addEventListener('click', handleActivity)
    document.addEventListener('mousemove', handleActivity)
    document.addEventListener('keydown', handleActivity)
  }, [handleActivity])

  const removeEventListeners = useCallback(() => {
    document.removeEventListener('click', handleActivity)
    document.removeEventListener('mousemove', handleActivity)
    document.removeEventListener('keydown', handleActivity)
  }, [handleActivity])

  useEffect(() => {
    resetUserActivityState()
  }, [resetUserActivityState])

  useEffect(() => {
    if (!inactivityTimeout) {
      clearActivityTimer()
      removeEventListeners()
    } else {
      addEventListeners()
    }
    return () => {
      clearActivityTimer()
      removeEventListeners()
    }
  }, [
    addEventListeners,
    clearActivityTimer,
    inactivityTimeout,
    removeEventListeners,
  ])

  useEffect(() => {
    const handleStorageEvent = (event: StorageEvent) => {
      if (event.key === LAST_ACTIVITY_TIME) {
        restartActivityTimer()
      }
    }
    window.addEventListener('storage', handleStorageEvent)

    return () => {
      window.removeEventListener('storage', handleStorageEvent)
    }
  }, [restartActivityTimer])

  return {
    isCountdownActive,
    clearActivityTimer,
    setIsCountdownActive,
    handleWorkerMessage,
    handleActivity,
  }
}
